import React, {useRef, useState, useEffect} from 'react'
import CustomTable from '../../../../widgets/table'
import Pagination from '../../../../widgets/pagination'
import { Column } from 'react-table';
import { FileService } from '../../../../service/fileServices';
import { Button, Modal } from 'react-bootstrap';
import { TfiClose } from 'react-icons/tfi';
import { FaRegClosedCaptioning } from 'react-icons/fa';
import AlertModal from '../../../../widgets/alertModal';
import { useTranslation } from 'react-i18next';
import baseUrl from '../../../../service';
import {userService as userRoleServive} from '../../../../service/userService';
import { userService as plateRelatedService, userService } from '../../../../service/plateServices';

import AddException from '../../../common/addException';
import { BatchService } from '../../../../service/plateServices/discard';
import { columnsByBatch, columnsByLotBatch } from './columns';
import { visualInspection } from '../../../../service/visualinspection';
import CustomToast from '../../../../widgets/toaster';
import PlateDetailsViewModel from '../../../lab_operator/labReport/models/plateDetailsModel';
import { workflow } from '../../../../service/workflow';
import { areaService } from '../../../../service/areaServices';
import { PlateReconcilationApi } from '../../../../service/plateReconcilation';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { RootState, setSerielNumbers } from "../../../../store/store";


interface PlatesProps {
    plateDetails: string[];
    columns: Column[];
    plateBatches: string[];
    totalElements: number;
    page: number;
    totalPages: number;
    onUploadClick: () => void;  
    onAddClick: () => void;
    setUseEffectRequired: (value: boolean) => void;
    handleFilter: (key: string, value: any) => void;
    handleSort: (key: string, value: boolean) => void;
    handlePageChange: (newPage: any) => void;
}

const Plates: React.FC<PlatesProps> = ({
    plateDetails,
    columns,
    plateBatches,
    totalElements,
    page,
    totalPages,
    handleFilter,
    handleSort,
    handlePageChange,
    onUploadClick,
    onAddClick,
    setUseEffectRequired
}) => {

  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();



  const [modalShow, setModalShow] = useState(false);
  const [modalPlateShow, setModalPlateShow] = useState(false);
  const [showGenerateLotQrAlert, setShowGenerateLotQrAlert] = useState(false);
  const [selectedSlnoLen, setSelectedSlnoLen] = useState(0)
  const [selectedSerielNumbers, setSelectedSerielNumbers] = useState<string[]>([]);
  // const [qrPrintModalShow, setQrPrintModalShow] = React.useState(false);
  const [qrColumns, setQrColumns] = useState(7);
  const [cellWidth, setCellWidth] = useState(1);
  const [imageId, setImageId] = useState("");
  const [showPlateSampleModal, setShowPlateSampleModal] = useState(false);
  const [showAddLotTable, setshowAddLotTable] = useState(false);
  interface Errors {
    remarks?: string;
    reason_id?: string;
    other_reason?: string;
    scan_plate?: string;
    batchCode?:string;
    quantity?:string;
    lot_code?:string;
    activity?:string;
    operator?:string;

  }
  const [showButtonWithCloseIcon, setShowButtonWithCloseIcon] = useState(false);
  const [closeIconText, setCloseIconText] = useState("");
  const [batchPlateList, setBatchPlateList] = useState([]);
  const [batchLotList, setBatchLotList] = useState([]);
  const [serielNumbatchLotList, setSerielNumbatchLotList] = useState([]);
  const [keyWord, setkeyWord] = useState("");

   // pagination
   const [BatchByPage, setBatchByPage] = useState(1);
   const [BatchByPageSize, setBatchByPageSize] = useState(5);
   const [BatchByTotalPages, setBatchByTotalPages] = useState(0);
   const [BatchByTotalElements, setBatchByTotalElements] = useState(0);

   const [lotByPage, setLotByPage] = useState(1);
   const [lotByPageSize, setLotByPageSize] = useState(5);
   const [lotByTotalPages, setLotByTotalPages] = useState(0);
   const [lotByTotalElements, setLotByTotalElements] = useState(0);

   const [sort, setSort] = useState("id");
   const [order, setOrder] = useState("desc");
   const [permission, setPermission] = useState<any>([]);
   const [lotNum, setLotNum] = useState([]);
   const [addLotData, setaddLotData] = useState({
    batchCode:"",
    plate_quantity: "",
    quantity: "",
    batch_id: "",
    lot_code: ""
    });
    interface NextSerial{
      next_serial_value?: any;
      lot_code?: any;
    }
   const [showAddLot , setAddLot] = useState(false);
   const [showGenerate , setShowGenerate] = useState(false);
   const [errors, setErrors] = useState<Errors>({});
  //  const [nextSerial, setNextSerial] = useState<NextSerial>({});
   const [showLotSubmittAlert, setShowLotSubmittAlert] = useState(false);
   const [showGenerateQrAlert, setShowGenerateQrAlert] = useState(false);
   const [showToast, setShowToast] = useState(false);
   const [toastMessage, setToastMessage] = useState("");
   const [toastType, setToastType] = useState("");
   const [selectedBatchId, setSelectedBatchId] = useState("");
   const [show, setShow] = useState(false);
   const [activity, setActivity] = useState<Array<any>>([]);
   const [userData , setUserData] = useState<Array<any>>([]); 
   const [location, setLocation] = useState<Array<any>>([]); 
   const [generateQrData, setGenerateQrData] = useState({
    batchCode:"",
    lotCode: "",
    lotId: "",
    startingSerielNo: "",
    availablePlates: "",
    quantity: "",
    activity: "",
    location: "",
    operator: ""
    });
    const [selectedLotDetails, setSelectedLotDetails] = useState<any>({});
    const [selectedPlateId, setSelectedPlateId] = useState<any>("");
    const [plateSerielNum, setPlateSerielNums] = useState([]);
    const [inspectionStatus, setInspectionStatus] = useState<any>("failed,passed,pending");







   const listLotNumber = async (batchNo: string) => {
    try {
      const response = await visualInspection.listFilterLots(batchNo);
      if(response.status && response.statusCode === 200){
        try {
            if ( Array.isArray(response.data) && response.data?.length > 0) {
              let rowData: any = response.data.map((element: any, index: number) => {

                return { 
                    value: element.lot_code,
                    label: element.lot_code ,
                };
              });
              setLotNum(rowData);
            }
          } catch (error) {
            console.log(error); 
          }
      }
    } catch (error) {
      console.error('Plate response', error);
    }
  }

  //serial number listing for table drop down 
  const listSerialNumber = async (batchNo: string, lotId: string) => {
    try {
      const response = await plateRelatedService.listSerialNumber(batchNo, lotId);
      if(response.status && response.statusCode === 200){
        try {
            if ( Array.isArray(response.data) && response.data?.length > 0) {
              let rowData: any = response.data.map((element: any, index: number) => {

                return { 
                    value: element.serial_number,
                    label: element.serial_number ,
                };
              });
              setPlateSerielNums(rowData);
            }
          } catch (error) {
            console.log(error); 
          }
      }
    } catch (error) {
      console.error('Plate response', error);
    }
  }


  // handle qr section 
  const handleQrDownload = async (slno: string[], type: string) => {
    const filteredUserDetails = batchLotList.filter((item: any) => slno.includes(item.slno));
    const selectedIds = filteredUserDetails.map((item: any) => item.qr_name).join(',');
    const selectedSerielNo = filteredUserDetails.map((item: any) => item.qr_name);
    setSelectedSlnoLen(slno.length)
    if (type === "single") {
      setShowGenerateQrAlert(true)
       setImageId(selectedIds)
    } else if (type === "multi") {
      setModalPlateShow(true)
      setSelectedSerielNumbers(filteredUserDetails)
    }
  }

  //generate Qr
  const handleGenerateQr = () => {
    setShowGenerateQrAlert(false)
    const imageUrl = `${baseUrl}/plates/download_plate_qr/?plate_id=${imageId}`;
    const link = document.createElement('a');
    link.href = imageUrl;
    link.download = 'downloaded_image.jpg';
    link.click();
  }

  //create grid with size 
  const createGrid = () => {
    return (
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              {selectedSerielNumbers.map((cellIndex : any) => (
                <div key={cellIndex} style={{ display: "flex", border: '0.5px solid grey', margin: "15px 15px", "alignItems" : "center"}}>
                  <img src={`${baseUrl}/uploads/qr/lots/${cellIndex.qr_name}`} alt={`Image ${cellIndex}`} style={{ width: `${cellWidth}cm`, height: `${cellWidth}cm`, pageBreakInside: 'avoid' }} />
                  <p style={{"fontSize": `${cellWidth + 10}px`, "fontWeight":"800", "margin": "2px",}} >{cellIndex.lot_code}</p>
                </div>
              ))}
            </div>
    )
  }

  const createPlateGrid = () => {
    return (
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        {selectedSerielNumbers.map((cellIndex : any) => (
          <div key={cellIndex} style={{ display: "flex", border: '0.5px solid grey', margin: "15px 15px", "alignItems" : "center"}}>
            <img src={`${baseUrl}/uploads/qr/plates/${cellIndex.qr_name}`} alt={`Image ${cellIndex.qr_name}`} style={{ width: `${cellWidth}cm`, height: `${cellWidth}cm`, pageBreakInside: 'avoid' }} />
            <div>
            <p style={{"fontSize": `${cellWidth + 10}px`, "fontWeight":"800", "margin": "2px",}} >{cellIndex?.serial_number}</p>
            <p style={{"fontSize": `${cellWidth + 10}px`, "fontWeight":"800", "margin": "2px",}} >{cellIndex?.assigned_operator_name}</p>
            <p style={{"fontSize": `${cellWidth + 10}px`, "fontWeight":"800", "margin": "2px",}} >{cellIndex?.assigned_location_name}</p>
            </div>
          </div>
        ))}
      </div>
)
  }

  //download QR
  const handleDownloadBtn = () => {
    const content : any = document.getElementById('innerDiv');
    const pri: any = window.open('', '', 'height=600,width=800');
    pri.document.write(content.innerHTML);
    pri.onafterprint = () => {
      pri.close();
      handleQRModalClose()
    };
    pri.print();

  }

  const handlePlateDownloadBtn = () => {
    const content : any = document.getElementById('innerDiv');
    const pri: any = window.open('', '', 'height=600,width=800');
    pri.document.write(content.innerHTML);
    pri.onafterprint = () => {
      pri.close();
      setModalPlateShow(false)
    };
    pri.print();
  }

  const handlePlateSampleClick = () => {
    setShowPlateSampleModal(true)
  }



  const ListLots = async (BatchByPage: any, BatchByPageSize: any, batchId: string, inspectionStatus: string, lotId?: string) => {
    try {
      const response = await PlateReconcilationApi.ListLots(BatchByPage, BatchByPageSize, batchId, lotId || "", inspectionStatus);
      if(response.status && response.statusCode === 200){
          if ( Array.isArray(response.data) && response.data?.length > 0) {
            setBatchByTotalPages(response?.totalPages)
            setBatchByTotalElements(response?.totalElements)
            let rowData: any = response.data.map((element: any, index: number) => {
                return { 
                    id : element.id,
                    slno: index + 1, 
                    batch_no: element.batch_no,
                    batch_id: element.batch_id,
                    lot_code: element.lot_code,
                    plate_count: element.plate_count,
                    inspection_status: element.inspection_status.charAt(0).toUpperCase() + element.inspection_status.slice(1),
                    remaining_plates_for_qr: element.remaining_plates_for_qr,
                    qr_name: element.qr_name
                };
            });
            setBatchPlateList(rowData);
          }
      } else {
        setBatchPlateList([]);
        setBatchByTotalPages(0);
        setBatchByTotalElements(0);
      }
    } catch (error) {
      return error;
    }
  }

  const handleBatchByPageChange = (newPage : any, pageSize?: number) => {
    setBatchByPage(newPage);
    if(pageSize) {
      setBatchByPageSize(pageSize)
      ListLots(newPage, pageSize, addLotData?.batch_id, inspectionStatus)
    } else {
      ListLots(newPage, BatchByPageSize, addLotData?.batch_id, inspectionStatus)
    }
  }

  const handleLotByPageChange = (newPage : any, pageSize?: number) => {
    setLotByPage(newPage);
    if(pageSize) {
      setLotByPageSize(pageSize)
      listBatchPlateDetails(newPage, pageSize , sort ,order, 0, selectedLotDetails?.batch_number, keyWord, selectedLotDetails?.id)
    } else {
      listBatchPlateDetails(newPage, lotByPageSize , sort ,order, 0, selectedLotDetails?.batch_number, keyWord, selectedLotDetails?.id)
    }

  }

  //get permission by role 
  const getPermissionByRole = async (role : number) => {
    try {
      const response = await userRoleServive.getPermissionByRole(role);
      if(response.status && response.statusCode === 200){
        setPermission(response.data.permissions)
      }
    } catch (error) {
      console.error(error);
    }
  }

  const handlePlateSampleClose = () => {
    setShowPlateSampleModal(false)
    setUseEffectRequired(true)
  }


  const handleEditClick = (id: any) => {
    setSelectedBatchId(id);
    listLotNumber(id);
    // listNextPlateSerial();
    getlistNextPlateSerial();
    const filteredUserDetails : any = plateDetails.filter((item: any) => item.batch_number === id);
    ListLots(BatchByPage, BatchByPageSize,filteredUserDetails[0]?.id, inspectionStatus)
    setshowAddLotTable(true)
    // listSerialNumber(filteredUserDetails[0]?.id, filteredUserDetails[0]?.lot_id)
    setaddLotData({
      batchCode: filteredUserDetails[0]?.batch_number,
      batch_id : filteredUserDetails[0]?.id,
      plate_quantity : filteredUserDetails[0]?.remaining_for_lot_assign,
      quantity: "",
      lot_code: ""
    })

    getActivityList("");
    getOperatorList();
    getLocationList();
  }

  const onAddLotClick = () => {
    const filteredUserDetails : any = plateDetails.filter((item: any) => item.batch_number === selectedBatchId);
    setaddLotData({
      batchCode: filteredUserDetails[0]?.batch_number,
      batch_id : filteredUserDetails[0]?.id,
      plate_quantity : filteredUserDetails[0]?.remaining_for_lot_assign,
      quantity: "",
      lot_code: ""
    })
      setAddLot(true)
  }

  const handleActionCheckOrangeButton = (selectedRows : any[]) => {
    const filteredPlateDetails = batchPlateList.filter((item: any) => selectedRows.includes(item.slno));
    const lotIds = filteredPlateDetails.map((obj : any) => obj.id)
    dispatch(setSerielNumbers(lotIds));
    navigate("/visualInspectionTest");
  }

  const handleAddPlateChange = (e : any) => {
      const { name, value } = e.target;

      let updatedValue = value;

      if (name === 'lot_code') {
        updatedValue = value.replace(/[^a-zA-Z0-9\s]/g, '');
      }

      setaddLotData((prevFormData : any) => ({
      ...prevFormData,
      [name]: updatedValue,
      }));
  };


  const addPlateClose = (e : any) => {
    const { name, value } = e.target;
    setAddLot(false)
    setErrors({})
    setaddLotData((prevFormData : any) => ({
      ...prevFormData,
      [name]: value,
      quantity: "",
      lot_code: "",
      }));
  }

const handlePlateAlert = () => {
  const errors: Errors = {};
  if (!addLotData.quantity) {
    errors.quantity = "Please Enter Quantity";
  }
  if (!addLotData.lot_code) {
    errors.lot_code = "Please Enter Lot Code";
  }
  if (Object.keys(errors).length > 0) {
    setErrors(errors);
    return;
  }
  setShowLotSubmittAlert(true)
  setErrors({});
}

const handleQrAlert = () => {
  const errors: Errors = {};
  if (!generateQrData.quantity) {
    errors.quantity = "Please Enter Quantity";
  }
  if (!generateQrData.operator) {
    errors.operator = "Please Enter Operator";
  }
  if (!generateQrData.activity) {
    errors.activity = "Please Enter Activity";
  }
  if (Object.keys(errors).length > 0) {
    setErrors(errors);
    return;
  }
  setShowGenerateLotQrAlert(true)
  setErrors({});
}

// const listNextPlateSerial = async () => {
//   try {
//     const response = await plateRelatedService.listNextLotCode();
//     if(response.status && response.statusCode === 200){
//       setNextSerial({ 
//         next_serial_value: response.data.next_serial_value,
//         lot_code: response.data.lot_code
//       });
//     }
//   } catch (error) {
//     console.error('user response', error);
//   }
// }

const getlistNextPlateSerial = async () => {
  try {
    const response = await plateRelatedService.listNextPlateSerial();
    if(response.status && response.statusCode === 200){
      setGenerateQrData((prevState) => ({
        ...prevState,
        startingSerielNo: response?.data.serial_number || ""
      }));
    }
  } catch (error) {
    console.error('user response', error);
  }
}

const lotSubmit = async () => {
  const dataToSend = {
      "batch_id": addLotData.batch_id,
      "plate_count":addLotData.quantity,
      "lot_code":addLotData.lot_code
  }

  try {
    const response = await userService.createNewLot(dataToSend);
    if(response.status && response.statusCode === 200){
      setToastType("success")
      setToastMessage(response.message)
      setaddLotData((prevFormData : any) => ({
        ...prevFormData,
        quantity: "",
        lot_code:""
        }));
        setAddLot(false)
    } else {
      setToastType("error")
      setToastMessage(response.message)
    }
    setShowToast(true)
    setShowLotSubmittAlert(false)
    setUseEffectRequired(true)
    const filteredUserDetails : any = plateDetails.filter((item: any) => item.batch_number === selectedBatchId);
    ListLots(BatchByPage, BatchByPageSize, filteredUserDetails[0]?.id, inspectionStatus)

    // addExceptionClose()


  } catch (error) {
    console.error('Plate response', error);
  }
}

//list plate batch detail 
const listBatchPlateDetails = async (BatchByPage: any, BatchByPageSize: any, sort:any , order:any, plateStatus: any, batchId: string, keyWord: any, lot_id: any) => {
  try {
    const response = await BatchService.listLotPlateDetails(BatchByPage, BatchByPageSize, sort, order, plateStatus, batchId, keyWord, lot_id);
    if(response.status && response.statusCode === 200){
        if ( Array.isArray(response.data) && response.data?.length > 0) {
          setLotByTotalPages(response?.totalPages)
          setLotByTotalElements(response?.totalElements)
          let rowData: any = response.data.map((element: any, index: number) => {
            const role = element.role_master_id === "1" ?
               "Admin" : element.role_master_id === "2" ?
               "Operator" : element.role_master_id === "3"? 
               "Lab Operator": null
               const statusNumber = parseInt(element.plate_status, 10); 
               const status = plateRelatedService.getPlateStatus(statusNumber);
              return { 
                  id : element.id,
                  slno: index + 1, 
                  batch_no: element.batch_number,
                  lot_code: element.lot_code,
                  serial_number: element.serial_number,
                  expiry_date: element.expiry_date,
                  manufacturing_date: element.manufacturing_date,
                  qr_name: element.qr_name,
                  assigned_location_name: element.assigned_location_name,
                  assigned_operator_name: element.assigned_operator_name,
                  status: status,
              };
          });

          let serielNum: any = response.data.map((element: any, index: number) => {
              return { 
                value: element.serial_number,
                label: element.serial_number ,
              };
          });
          setBatchLotList(rowData);
          setSerielNumbatchLotList(serielNum)
        }
    } else {
      setBatchLotList([]);
      setLotByTotalPages(0);
      setLotByTotalElements(0);
    }
  } catch (error) {
    return error;
  }

}

//on cell click 
const handleCellClick = (rowData: any) => {
  if(rowData.column.id === "plate_count"){
    setShowButtonWithCloseIcon(true)
    setSelectedLotDetails(rowData?.row?.original)
    setCloseIconText(rowData?.row?.original?.batch_no)
    listSerialNumber("", rowData?.row?.original?.id)
    listBatchPlateDetails(lotByPage, lotByPageSize , sort ,order, 0, rowData?.row?.original?.batch_number, keyWord, rowData?.row?.original?.id)
  }
}

//on click on selected incubator list 
const handleCloseBtnClick = () => {
  setShowButtonWithCloseIcon(false);
  const filteredUserDetails : any = plateDetails.filter((item: any) => item.batch_number === selectedBatchId);
  ListLots(BatchByPage, BatchByPageSize, filteredUserDetails[0]?.id, inspectionStatus)
  setBatchLotList([])
  setPlateSerielNums([])
}

//function while clicking view icon in table 
const handleViewClick = (id: string, viewValue: boolean) => {
  const updatedData : any = batchLotList.filter((row: any) => row.slno === id);
  setSelectedPlateId(updatedData[0]?.id)
  setShow(true)
}

const handleGenerateQrBtn = (id: any) => {
  setShowGenerate(true)
  const filteredPlateLot: any = batchPlateList.filter((item: any) => item.slno === id);
  if(filteredPlateLot[0].inspection_status === "Pending"){
    getActivityList("5")
  }else{
    getActivityList("")
  }
  setGenerateQrData((prevState) => ({
    ...prevState,
    lotCode: filteredPlateLot[0].lot_code || "",
    lotId: filteredPlateLot[0].id || "",
    batchCode: filteredPlateLot[0].batch_no || "",
    availablePlates: filteredPlateLot[0].remaining_plates_for_qr || "0"
  }));
}

// function to get operator api list
const getOperatorList = async () => {
  try { 
    const response = await userRoleServive.getUserNameList("");
    if(response.status && response.statusCode === 200){
      try {
          if ( Array.isArray(response.data) && response.data?.length > 0) {
            let rowData: any = response.data.map((element: any, index: number) => {

              return { 
                  slno: index + 1, 
                  id: element.id,
                  name: `${element.first_name} ${element.last_name}`,
              };
            });
            setUserData(rowData);
          }
        } catch (error) {
          console.log(error);
        }
    } else {
      setUserData([]);
    }
  } catch (error) {
    console.error(error);
  }
};

// function to get activity api list
const getActivityList = async (activity_id: string) => {
  try {
      const response = await workflow.ActivityList(activity_id);
      if (response.status && response.statusCode === 200) {
        try {
          if (Array.isArray(response.data) && response.data?.length > 0) {
            let rowData: any = response.data.map((element: any) => {
              return {
                id: element.id,
                name: element.name,
              };
            });
            setActivity(rowData);
          }
        } catch (error) {
          console.log(error);
        }
      }
    } catch (error) {
      console.error("user response", error);
    }
}

// function to get location api list
 const getLocationList = async () => {
  try {  
    const response = await areaService.getParentAreaList([11,13,15,10,18]);
    if(response.status && response.statusCode === 200){
      try {
          if ( Array.isArray(response.data) && response.data?.length > 0) {
            let rowData: any = response.data.map((element: any, index: number) => {

              return { 
                  slno: index + 1, 
                  policy_id : element.policy_id,
                  id : element.id,
                  name: element.name,
              };
            });
            setLocation(rowData);
          }
        } catch (error) {
          console.log(error);
        }
    }
  } catch (error) {
    console.error('user response', error);
  }
}

const handleQrGenerateValueChange = (e : any) => {
  const { name, value } = e.target;
  setGenerateQrData((prevFormData : any) => ({
  ...prevFormData,
  [name]: value,
  }));
};

const handleCloseGenerateQr = () => {
  setShowGenerate(false)
  setGenerateQrData((prevState) => ({
    ...prevState,
    quantity: "",
    activity: "",
    location: "",
    operator: ""
  }));
}

const handleQrGeneraterSubmit = async () => {
  const dataToSend = {
    "lot_id" : generateQrData.lotId,
    "quantity" : generateQrData.quantity,
    "master_activity_id": generateQrData.activity,
    "location_id": generateQrData.location, 
    "operator_id" : generateQrData.operator
  }

  try {
    const response = await userService.createLotQR(dataToSend);
    if(response.status && response.statusCode === 200){
      setToastType("success")
      setToastMessage(response.message)
      handleCloseGenerateQr()
    } else {
      setToastType("error")
      setToastMessage(response.message)
    }
    setShowToast(true)
    setShowGenerateLotQrAlert(false)
    // setAddLot(false)
    // const filteredUserDetails : any = plateDetails.filter((item: any) => item.batch_number === selectedBatchId);
    // ListLots(BatchByPage, BatchByPageSize,sort, filteredUserDetails[0]?.id, closeIconText)

    // handleCloseGenerateQr()

  } catch (error) {
    console.error('Plate response', error);
  }
}

//handle single and multi QR download button click
const handlePlateQrDownload = async (slno: string[], type: string) => {
  const filteredUserDetails = batchPlateList.filter((item: any) => slno.includes(item.slno));
  const selectedIds = filteredUserDetails.map((item: any) => item.qr_name).join(',');
  console.log(filteredUserDetails, "filteredUserDetails")

  const selectedSerielNo = filteredUserDetails.map((item: any) => item.qr_name);
  console.log(selectedIds, "filteredUserDetails")
  console.log(type, "filteredUserDetails")
  setSelectedSlnoLen(slno.length)
  if (type === "single") {
    setShowGenerateQrAlert(true)
     setImageId(selectedIds)
  } else if (type === "multi") {
    setModalShow(true)
    setSelectedSerielNumbers(filteredUserDetails)
  }
}

const handlePlateDetailsListFilter = (key: string, value: string) => {
  if(key === "search") {
    listBatchPlateDetails(lotByPage, lotByPageSize , sort ,order, 0, selectedLotDetails?.batch_number, value, selectedLotDetails?.id)
  }
}

const handleBatchPlateListFilter = (key: string, value: any) => {
  if (key === "lot_code") {
    const filteredLotDetails:any = batchPlateList.filter((item: any) => item.lot_code === value);
    const filteredBatchDetails : any = plateDetails.filter((item: any) => item.batch_number === selectedBatchId);  
    ListLots(BatchByPage, BatchByPageSize, filteredBatchDetails[0]?.id, inspectionStatus, filteredLotDetails[0]?.id)
  }
  if (key === "visualInspectionStatus") {
    setInspectionStatus(value)
    ListLots(BatchByPage, BatchByPageSize, addLotData?.batch_id, value)
  }
};

const handleQRModalClose = () => {
  setModalShow(false)
  setCellWidth(1)
}


useEffect (() => {
  var storedData = localStorage.getItem('userDetails');

  if(storedData) {
    var storedObject = JSON.parse(storedData);
    getPermissionByRole(storedObject[0].role_master_id);
  }
}, [])


  const onCloseButtonClick = () =>{
    setBatchPlateList([])
    setshowAddLotTable(false)
    setaddLotData({ 
      batchCode:"",
      plate_quantity: "",
      quantity: "",
      batch_id: "",
      lot_code: ""
    })
  }

  return (
    <div className="me-3" style={{ marginTop: "10px" }}>
    <CustomToast
            show={showToast}
            onClose={() => setShowToast(false)}
            delay={5000}
            message={toastMessage}
            toastType={toastType}
        />
    {!showAddLotTable && (
        <>
        <div className=" mx-3 pe-2" style={{"height":"45vh"}}>
        <CustomTable
              tableHead="Plates"
              data={plateDetails}
              columns={columns}
              isEditable={false}
              totalElements={totalElements}
              isActionVisible={false}
              isViewVisible={false}
              addButton={true}
              addButtonText={t('plateInventory.fileUpload')}
              onAddClick={onAddClick}
              addPlateUploadBtn={permission?.includes("add_plates")}
              addPlateUploadText={'Bulk Upload'}
              showBlueBtn={permission?.includes("plate_sampling_test")}
              blueBtnText='Plate Sampling'
              onBlueBtnClick={handlePlateSampleClick}
              onUploadClick={onUploadClick}
              // plateSerialNumber={plateSerielNum}
              plateBatch={plateBatches}
              isSeachable={false}
              showPlateStatus={true}
              onFilterClick={handleFilter}
              showaddLotButton={true}
              onSortClick={handleSort}
              onEditClick={handleEditClick}
              showFromDateFilter={true}
              showToDateFilter={true}
             
            />
          </div>
       
            <div> 
                <Pagination
                page={page}
                totalPages={totalPages}
                handlePageChange={handlePageChange}
              />
            </div>
        </>
        )}

        {showAddLotTable && (
          !showButtonWithCloseIcon ? (
          <div>
          <div className=" mx-3 pe-2" style={{"height":"45vh"}}>
            <CustomTable
              tableHead="Plates Lot"
              data={batchPlateList}
              columns={columnsByBatch}
              isEditable={false}
              totalElements={BatchByTotalElements}
              isActionVisible={true}
              isViewVisible={false}
              addButton={false}
              addButtonText={'Add Lot'}
              onAddClick={onAddLotClick}
              lotCodeNumber={lotNum}
              isSeachable={false}
              showPlateStatus={false}
              onSortClick={handleSort}
              onCellClick={handleCellClick}
              buttonWithCloseIcon={true}
              buttonWithCloseIconText={"Cancel"}
              onCloseBtnClick={onCloseButtonClick}
              onEditClick={handleGenerateQrBtn}
              onFilterClick={handleBatchPlateListFilter}
              isQrDownloadable={true}
              actionCheckOrangeButton={true}
              actionCheckOrangeBtnText='Visual Inspection Test'
              onActionCheckOrangeButtonClick={handleActionCheckOrangeButton}
              onQrDownloadClick={handlePlateQrDownload}
              showQrGenerateButton={true}
            />
          </div>
            <div>
            <Pagination
                  page={BatchByPage}
                  totalPages={BatchByTotalPages}
                  handlePageChange={handleBatchByPageChange}
                />
            </div>
          </div>
          ) : (
            <div>
            <div className='mx-3 pe-3' style={{"height":"45vh"}}>
              <CustomTable
                tableHead='Plate Details' 
                data={batchLotList} 
                columns={columnsByLotBatch} 
                isEditable={false} 
                isActionVisible={true} 
                isViewVisible={true} 
                totalElements={lotByTotalElements}
                isSeachable={true}
                isToggleStatus={false}  
                plateSerialNumber={plateSerielNum}    
                onViewClick={handleViewClick}
                showSerielNoFilter={true}
                onFilterClick={handlePlateDetailsListFilter}
                buttonWithCloseIcon={showButtonWithCloseIcon}
                buttonWithCloseIconText={closeIconText}
                onCloseBtnClick={() => handleCloseBtnClick()}
                isQrDownloadable={true}
                onQrDownloadClick={handleQrDownload}
                showLotCodeFilter={false}
                // onSortClick={handleSortClicked}
            />
            </div>
            <div>
              <Pagination
                page={lotByPage}
                totalPages={lotByTotalPages}
                handlePageChange={handleLotByPageChange}
              />
            </div>
            </div>
          )
        )}

          
          {/* <div>
            {showAddLotTable &&(
              <Pagination
                page={BatchByPage}
                totalPages={BatchByTotalPages}
                handlePageChange={handleBatchByPageChange}
              />
            )} */}
            {/* {showButtonWithCloseIcon &&(
              <Pagination
                page={BatchByPage}
                totalPages={BatchByTotalPages}
                handlePageChange={handleBatchByPageChange}
              />
            )} */}
          {/* </div> */}

          <Modal
              // size="md"
              aria-labelledby="contained-modal-title-vcenter"
              centered
              show={modalShow}
            >
              <Modal.Header closeButton onClick={() => handleQRModalClose()}>
                <Modal.Title id="contained-modal-title-vcenter">
                {t('plateInventory.download')}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p>Selected {selectedSlnoLen} plates to download QR</p>
                <div className="selectWrap">
                 
                  <div className="form-group my-1">
                    <label>{t('plateInventory.cell_width')}</label>
                    <br />
                    <input 
                       type="number"
                       id="cellWidth"
                       value={cellWidth}
                       min="1"
                       onChange={(e) => {
                        const value = parseInt(e.target.value);
                        if (value > 0) {
                          setCellWidth(value); // Update state only if value is greater than zero
                        }
                      }}
                    />
      
                  </div>
                </div>
                <div id="innerDiv" hidden style={{ border: '1px solid black', padding: '5px', margin:"5px", overflow: "scroll",height: "80vh"}}>
                  {createGrid()}
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button onClick={() => handleDownloadBtn()}>{t('buttons.submit')}</Button>
              </Modal.Footer>
          </Modal>

          <Modal
              // size="md"
              aria-labelledby="contained-modal-title-vcenter"
              centered
              show={modalPlateShow}
            >
              <Modal.Header closeButton onClick={() => setModalPlateShow(false)}>
                <Modal.Title id="contained-modal-title-vcenter">
                {t('plateInventory.download')}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p>Selected {selectedSlnoLen} plate(s) to download QR</p>
                <div className="selectWrap">
                 
                  <div className="form-group my-1">
                    <label>{t('plateInventory.cell_width')}</label>
                    <br />
                    <input 
                       type="number"
                       id="cellWidth"
                       value={cellWidth}
                       min="1"
                       onChange={(e) => {
                        const value = parseInt(e.target.value);
                        if (value > 0) {
                          setCellWidth(value); // Update state only if value is greater than zero
                        }
                      }}
                    />
      
                  </div>
                </div>
                <div id="innerDiv" hidden style={{ border: '1px solid black', padding: '5px', margin:"5px", overflow: "scroll",height: "80vh"}}>
                  {createPlateGrid()}
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button onClick={() => handlePlateDownloadBtn()}>{t('buttons.submit')}</Button>
              </Modal.Footer>
          </Modal>


         <AddException showModel={showPlateSampleModal} isPlateSampling={true} setShowModel={() => handlePlateSampleClose()} />

        <PlateDetailsViewModel 
          showView={show} 
          setShowView={(value: boolean) => setShow(value)}
          plateId={selectedPlateId}
          apiType='plateById'
        />


          {/* Alert modal for reset password */}
        <AlertModal
          show={showGenerateQrAlert}
          onHide={() => setShowGenerateQrAlert(false)}
          title='Alert'
          message={`Download QR`}
          onConfirm={handleGenerateQr}
        /> 

         {/* Alert modal for lot upload */}
         <AlertModal
          show={showLotSubmittAlert}
          onHide={() => setShowLotSubmittAlert(false)}
          title='Alert'
          message={`Create Lot`}
          onConfirm={lotSubmit}
        />

        {/* Alert modal for Qr Generator */}
        <AlertModal
          show={showGenerateLotQrAlert}
          onHide={() => setShowGenerateLotQrAlert(false)}
          title='Alert'
          message={`Generate QR`}
          onConfirm={handleQrGeneraterSubmit}
        />

        {/* Alert modal for add lot */}
        <Modal
              className="top-right-modal"
              centered={false}
              show={showAddLot}
          >
            <div>
                <div className="modalHead">
                <p className="modalHeadText ms-3 pt-3 p-2">Add Lot</p>
                <div onClick={(e) => addPlateClose(e)} className="p-2 mx-3 my-2 ">
                  <TfiClose fill="#FFFFFF" />
                </div>
              </div>
              <div>
                  <div className="selectWrap">
                  <div className="form-group my-1">
                    <label htmlFor="">{t('plateInventory.batch_code')}</label>
                    <br />
                    <input 
                       id="batchCode"
                       value={addLotData.batchCode}
                       name="batchCode"
                       type='text'
                       placeholder='Batch Code'
                       className="placeholder-style"
                       disabled
                       onChange={handleAddPlateChange}
                    />
                    {errors.batchCode && <p className="errorText">{errors.batchCode}</p>}
                  </div>

                  <div className="form-group my-1">
                    <label>{t('plateInventory.plate_quantity')}</label>
                    <br />
                    <input 
                       id="plate_quantity"
                       value={addLotData.plate_quantity}
                       name="plate_quantity"
                       type='text'
                       placeholder='Plate Quantity'
                       className="placeholder-style"
                       disabled
                       onChange={handleAddPlateChange} 
                    />
                  </div>
                </div>
                <div className="selectWrap">
                  <div className="form-group my-1">
                    <label htmlFor="">{t('plateInventory.quantity')} <span style={{ color: 'red' }}>*</span></label>
                    <br />
                    <input 
                      id="quantity"
                      value={addLotData.quantity}
                      name="quantity"
                      type='number'
                       placeholder='Quantity'
                       min={1}
                       className="placeholder-style"
                      onChange={handleAddPlateChange} 
                    />
                    {errors.quantity && <p className="errorText">{errors.quantity}</p>}
                  </div>

                  <div className="form-group my-1">
                    <label htmlFor="">{t('plateInventory.lot_code')} <span style={{ color: 'red' }}>*</span></label>
                    <br />
                    <input 
                       id="lot_code"
                       value={addLotData.lot_code}
                       name="lot_code"
                       type='text'
                       placeholder='Lot Code'
                       className="placeholder-style"
                       onChange={handleAddPlateChange} 
                    />
                    {errors.lot_code && <p className="errorText">{errors.lot_code}</p>}
                  </div>
                </div>
              </div> 
              <div className="bottomArea">
            <button onClick={(e) => addPlateClose(e)} className="modalCancelBtn">
           {t('buttons.cancel')}
            </button> 
            <button onClick={() => handlePlateAlert()} className="squareOrangeBtn3">{t('buttons.submit')}</button>
          </div>
            </div>
        </Modal> 

        {/* Alert modal for generate Qr */}
        <Modal
              className="top-right-modal"
              centered={false}
              show={showGenerate}
          >
            <div>
                <div className="modalHead">
                <p className="modalHeadText ms-3 pt-3 p-2">Generate QR </p>
                <div onClick={() => handleCloseGenerateQr()} className="p-2 mx-3 my-2 ">
                  <TfiClose fill="#FFFFFF" />
                </div>
              </div>
              <div>
                  <div className="selectWrap">
                  <div className="form-group my-1">
                    <label htmlFor="">{t('plateInventory.batch_code')}</label>
                    <br />
                    <input 
                       id="batchCode"
                       value={generateQrData.batchCode}
                       name="batchCode"
                       type='text'
                       placeholder='Batch Code'
                       className="placeholder-style"
                       disabled
                       onChange={handleQrGenerateValueChange}
                    />
                    {/* {errors.batchCode && <p className="errorText">{errors.batchCode}</p>} */}
                  </div>

                  <div className="form-group my-1">
                    <label>Lot Code</label>
                    <br />
                    <input 
                       id="lotCode"
                       value={generateQrData.lotCode}
                       name="lotCode"
                       type='text'
                       placeholder='Lot Code'
                       className="placeholder-style"
                       disabled
                       onChange={handleQrGenerateValueChange} 
                    />
                  </div>
                </div>
                <div className="selectWrap">
                  <div className="form-group my-1">
                    <label htmlFor="">Starting Seriel No <span style={{ color: 'red' }}>*</span></label>
                    <br />
                    <input 
                      id="startingSerielNo"
                      value={generateQrData.startingSerielNo}
                      name="startingSerielNo"
                      type='text'
                      placeholder='Starting Seriel No'
                      className="placeholder-style"
                      disabled
                      onChange={handleQrGenerateValueChange} 
                    />
                    {/* {errors.quantity && <p className="errorText">{errors.quantity}</p>} */}
                  </div>

                  <div className="form-group my-1">
                    <label htmlFor="">Available Plates <span style={{ color: 'red' }}>*</span></label>
                    <br />
                    <input 
                       id="availablePlates"
                       value={generateQrData.availablePlates}
                       name="availablePlates"
                       type='text'
                       placeholder='Available Plates'
                       className="placeholder-style"
                       onChange={handleQrGenerateValueChange} 
                       disabled
                    />
                    {/* {errors.lot_code && <p className="errorText">{errors.lot_code}</p>} */}
                  </div>
                </div>
                <div className="selectWrap">
                  <div className="form-group my-1">
                    <label htmlFor="">Quantity <span style={{ color: 'red' }}>*</span></label>
                    <br />
                    <input 
                      id="quantity"
                      value={generateQrData.quantity}
                      name="quantity"
                      type='number'
                      placeholder='Quantity'
                      min={1}
                      className="placeholder-style"
                      onChange={handleQrGenerateValueChange} 
                    />
                    {errors.quantity && <p className="errorText">{errors.quantity}</p>}
                  </div>

                  <div className='form-group my-1'>
                    <label htmlFor="">
                      Activity<span style={{ color: "red" }}>*</span>
                    </label>
                    <br />
                    <select
                      id="activity"
                      value={generateQrData.activity}
                      name="activity"
                      onChange={handleQrGenerateValueChange}
                    >
                      <option value="">Select</option>
                      {activity.map((type, index) => (
                        <option key={index} value={type?.id}>
                          {type?.name}
                        </option>
                      ))}
                    </select>
                    {errors.activity && (
                      <p className="errorText">{errors.activity}</p>
                    )}
                  </div>
                </div>

                <div className="selectWrap">
                  <div className='form-group my-1'>
                    <label htmlFor="">
                      Location
                    </label>
                    <br />
                    <select
                      id="location"
                      value={generateQrData.location}
                      name="location"
                      onChange={handleQrGenerateValueChange}
                    >
                      <option value="">Select</option>
                      {location.map((type, index) => (
                        <option key={index} value={type?.id}>
                          {type?.name}
                        </option>
                      ))}
                    </select>
                    {/* {errors.reason_id && (
                      <p className="errorText">{errors.reason_id}</p>
                    )} */}
                  </div>

                  <div className='form-group my-1'>
                    <label htmlFor="">
                      Operator<span style={{ color: "red" }}>*</span>
                    </label>
                    <br />
                    <select
                      id="operator"
                      value={generateQrData.operator}
                      name="operator"
                      onChange={handleQrGenerateValueChange}
                    >
                      <option value="">Select</option>
                      {userData.map((type, index) => (
                        <option key={index} value={type?.id}>
                          {type?.name}
                        </option>
                      ))}
                    </select>
                    {errors.operator && (
                      <p className="errorText">{errors.operator}</p>
                    )}
                  </div>
                </div>
             
              </div> 
              <div className="bottomArea">
            <button onClick={() => handleCloseGenerateQr()} className="modalCancelBtn">
           {t('buttons.cancel')}
            </button> 
            <button onClick={handleQrAlert} className="squareOrangeBtn3">{t('buttons.submit')}</button>
          </div>
            </div>
        </Modal> 

    </div>
  )
}

export default Plates