import React, { useEffect, useState } from 'react'
import CustomToast from '../../../../widgets/toaster';
import PageHeader from '../../../common/pageHeader';
import HeaderSection from '../../../common/headerSection';
import { useTranslation } from 'react-i18next';
import { userService as plateRelatedService, userService } from '../../../../service/plateServices';
import Select from 'react-select';
import CustomTable from '../../../../widgets/table';
import { ActivityWiseReconcilationColums, BatchWiseReconcilationColums, columns, LotWiseReconcilationColums, PlateWiseReconcilationColums } from '../partials/columns';
import Pagination from '../../../../widgets/pagination';
import { PlateReconcilationApi } from '../../../../service/plateReconcilation';
import { visualInspection } from '../../../../service/visualinspection';





const PlateReconcilationSummary = () => {

    const { t } = useTranslation()


    const [showDownloadCSV, setShowDownloadCSV] = useState(true);
    const [isApplyClicked, setIsApplyClicked] = useState(true);
    const [addFilterData, setAddFilterData] = useState({
        event: "",
        interface : "",
        role : "",
        employee:"",
        fromDate: "",
        toDate: "",
      });
    const [plateBatches, setPlateBatches] = useState([]);
    const [plateDetails, setPlateDetails] = useState<Array<any>>([]);
    const [selectedBatch , setSelectedBatch] = useState(""); 
    const [batchlist , setBatchList] = useState<Array<any>>([]); 
    const [dropDownlotList , setDropDownlotList] = useState<Array<any>>([]); 
    const [lotList , setLotList] = useState<Array<any>>([]); 
    const [visibleTable, setVisibleTable] = useState('1');
    const [plateSerielNum, setPlateSerielNums] = useState([]);
    const [activityWiseList , setActivityWiseList] = useState<Array<any>>([]); 
    const [activityConciliationType, setActivityConciliationType] = useState("");





    // pagination
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(5);
    const [totalPages, setTotalPages] = useState(0);
    const [totalElements, setTotalElements] = useState(0);

    const [lotWisePage, setLotWisePage] = useState(1);
    const [lotWisePageSize, setLotWisePageSize] = useState(5);
    const [lotWiseTotalPages, setLotWiseTotalPages] = useState(0);
    const [lotWiseTotalElements, setLotWiseTotalElements] = useState(0);

    const [plateWisePage, setPlateWisePage] = useState(1);
    const [PlateWisePageSize, setPlateWisePageSize] = useState(5);
    const [PlateWiseTotalPages, setPlateWiseTotalPages] = useState(0);
    const [PlateWiseTotalElements, setPlateWiseTotalElements] = useState(0);

    const [activityWisePage, setActivityWisePage] = useState(1);
    const [activityWisePageSize, setActivityWisePageSize] = useState(5);
    const [activityWiseTotalPages, setActivityWiseTotalPages] = useState(0);
    const [activityWiseTotalElements, setActivityWiseTotalElements] = useState(0);

    const [useEffectRequired, setUseEffectRequired] = useState(false);
    const [lotCallFlag, setLotCallFlag] = useState(false);
    const [lotid, setLotId] = useState(false);
    const [batchid, setBatchId] = useState("");
    const [lotNum, setLotNum] = useState([]);


    const handleFilterDataChange = (e : any) => {
        setShowDownloadCSV(true)
        setIsApplyClicked(false)
        const { name, value } = e.target;
        setAddFilterData((prevFormData : any) => ({
            ...prevFormData, 
            [name]: value,
        }));
    };

    const handleCancel = () => {
        const initialFormData = {
        event: "",
        interface : "",
        role : "",
        employee:"",
        fromDate: "",
        toDate: "",
        };
        setAddFilterData(initialFormData);
        // setUseEffectRequired(true)
        // listAuditTrialReport();
      }

    //batch list for filter dropdown 
    const listPlateBatch = async () => {
        try {
        const response = await plateRelatedService.listPlateBatch();
        if(response.status && response.statusCode === 200){
            try {
                if ( Array.isArray(response.data) && response.data?.length > 0) {
                let rowData: any = response.data.map((element: any, index: number) => {
    
                    return { 
                        value: element.batch_number,
                        label: element.batch_number ,
                    };
                });
                setPlateBatches(rowData);
                }
            } catch (error) {
                console.log(error); 
            }
        }
        } catch (error) {
        console.error('Plate response', error);
        }
    }

    //lot listing for filter dropdown
    const getDropLotList = async () => {
        try {
            const response = await visualInspection.listLotCodeMainPage("");
            console.log("data--", response)
            if(response.status && response.statusCode === 200){
              try {
                  if ( Array.isArray(response.data) && response.data?.length > 0) {
                    let rowData: any = response.data.map((element: any, index: number) => {
      
                      return { 
                          value: element.lot_code,
                          label: element.lot_code,
                          batchCode: element.batch_no,
                          key: "lotCode"
                      };
                    });
                    setDropDownlotList(rowData);
                    console.log("data--", rowData)
                  }
                } catch (error) {
                  console.log(error); 
                }
            }
          } catch (error) {
            console.error('Plate response', error);
          }
    }

    //serial number listing for table drop down 
    const listSerialNumber = async () => {
        try {
        const response = await plateRelatedService.listSerialNumber();
        if(response.status && response.statusCode === 200){
            try {
                if ( Array.isArray(response.data) && response.data?.length > 0) {
                let rowData: any = response.data.map((element: any, index: number) => {

                    return { 
                        value: element.serial_number,
                        label: element.serial_number,
                        key: "serielNumber",
                    };
                });
                setPlateSerielNums(rowData);
                }
            } catch (error) {
                console.log(error); 
            }
        }
        } catch (error) {
        console.error('Plate response', error);
        }
    }

    const handleSelectChange = (selectedOption : any) => {
        console.log(selectedOption, "selectedOption")
        // if (selectedOption?.key === "batchCode") {
        //   setSelectedBatch(selectedOption?.value);
        // }
        // if (selectedOption?.key === "serielNumber") {
        //   getPlateDetailsBySerielNo(selectedOption?.value)
        // }
        // if (selectedOption?.key === "lotCode") {
        //   setSelectedBatch(selectedOption?.batchCode);
        // }
      };

    //handle table pagination 
    const handlePageChange = (newPage : any, pageSize?: number) => {
      setPage(newPage);
      if(pageSize) {
        setPageSize(pageSize)
      }
      setUseEffectRequired(true)
    };

    //handle lotwise table pagination 
    const handlePageChangeLot = (newPage : any, pageSize?: number) => {
      setPage(newPage);
      if(pageSize) {
        setPageSize(pageSize)
      }
      setLotCallFlag(true)
    };

    //handle platewise table pagination 
    const handlePlateWisePageChange = (newPage : any, pageSize?: number) => {
      setPlateWisePage(newPage);
      if(pageSize) {
        setPlateWisePageSize(pageSize)
        getPlateList(newPage, pageSize, batchid)
      } else {
        getPlateList(newPage, PlateWisePageSize, batchid)
      }
    };

    //handle activity wise table pagination 
    const handleActivityWisePageChange = (newPage : any, pageSize?: number) => {
      setActivityWisePage(newPage);
      if(pageSize) {
        setActivityWisePageSize(pageSize)
        getActivityConcilation(newPage, pageSize, batchid, "batchId")
      } else {
        getActivityConcilation(newPage, activityWisePageSize, batchid, "batchId")
      }
    };
    
    //handle lot wise table pagination 
    const handleLotWisePageChangeLot = (newPage : any, pageSize?: number) => {
      setLotWisePage(newPage);
      if(pageSize) {
        setLotWisePageSize(pageSize)
        getLotList(newPage, pageSize, batchid)
      } else {
        getLotList(newPage, lotWisePageSize, batchid)
      }
    };

    //batch table starts here
    const getBatchList = async (selectedBatch : any) => {
        try {
          const response = await PlateReconcilationApi.getBatchList(page, pageSize, selectedBatch)
          console.log(response.data, "response")
          if(response.status && response.statusCode === 200){
            try {
                if ( Array.isArray(response.data) && response.data?.length > 0) {
                  setTotalPages(response?.totalPages)
                  setTotalElements(response?.totalElements)
                  let rowData: any = response.data.map((element: any, index: number) => {
      
                    const StatusConstants = {
                      QUARANTINE: "0",
                      QA_APPROVED: "1",
                      READY_TO_USE: "2",
                      ACTIVITY_INPROGRESS: "3",
                      QA_REJECTED : "4",
                      UNDER_TEST:"5",
                      BATCH_DISCARD:"6"
                    };
      
                    let status;
      
                    // Mapping numeric status to string representation
                    switch (element.batch_status) {
                        case StatusConstants.UNDER_TEST:
                            status = "Waiting for QA Approval";
                            break;
                        case StatusConstants.QA_APPROVED:
                            status = "QA APPROVED";
                            break;
                        case StatusConstants.READY_TO_USE:
                            status = "READY TO USE";
                            break;
                        case StatusConstants.ACTIVITY_INPROGRESS:
                            status = "ACTIVITY INPROGRESS";
                            break;
                        case StatusConstants.QA_REJECTED:
                            status = "QA REJECTED";
                            break;
                        case StatusConstants.QUARANTINE:
                            status = "QUARANTINE";
                            break;
                        case StatusConstants.BATCH_DISCARD:
                            status = "DISCARD";
                            break;
                        default:
                            status = "UNKNOWN STATUS";
                    }
      
                    return { 
                        id : element.id,
                        slno: index + 1, 
                        batch_code: element.batch_no,
                        lot_count: element.lot_count,
                        plates_available: element.plate_count,
                        plates_used: element.used_plates_count,
                        remaining_plates: element.remaining_plates_count,
                    };
                  });
                  setBatchList(rowData);
                }
              } catch (error) {
                console.log(error); 
              }
          } else {
            setBatchList([])
          }
        } catch (error) {
          console.error('Plate response', error);
        }
      
    }

    //lot wise listing
    const getLotList = async (page: number, pageSize: number, batchId: any) => {
        try {
          const response = await PlateReconcilationApi.ListLots(page, pageSize, batchId, "", "failed,passed,pending")
          if(response.status && response.statusCode === 200){
            try {
                if ( Array.isArray(response.data) && response.data?.length > 0) {
                  setLotWiseTotalPages(response?.totalPages)
                  setLotWiseTotalElements(response?.totalElements)
                  let rowData: any = response.data.map((element: any, index: number) => {
      
                    return { 
                        id : element.id,
                        slno: index + 1, 
                        lot_code: element.lot_code,
                        plates_availables: element.plate_count,
                        plates_used: element.used_plates_count,
                        remaining_plates: element.remaining_plates_count,
                    };
                  });
                  setLotList(rowData);
                }
              } catch (error) {
                console.log(error); 
              }
          } else {
            setLotList([])
          }
        } catch (error) {
          console.error('Plate response', error);
        }
      
    }

    //on cell click 
    const handleCellClick = (rowData: any) => {
        if(rowData.column.id === "lot_count"){
            getLotList(lotWisePage, lotWisePageSize, rowData?.row?.original?.id)
            setBatchId(rowData?.row?.original?.id)
            listLotNumber(rowData?.row?.original?.batch_code)
            setVisibleTable("2")
        }
        if(rowData.column.id === "plates_used"){
            setVisibleTable("3")
            getActivityConcilation(activityWisePage, activityWisePageSize, rowData?.row?.original?.id, "batchId")
            setBatchId(rowData?.row?.original?.id)
            listLotNumber(rowData?.row?.original?.batch_code)
        }
        if(rowData.column.id === "plates_available"){
            setVisibleTable("4")
            getPlateList(plateWisePage, PlateWisePageSize, rowData?.row?.original?.id)
            setBatchId(rowData?.row?.original?.id)
        }
        // setShowButtonWithCloseIcon(true)
        // setCloseIconText(rowData?.row?.original?.batch_number)
        // listBatchPlateDetails(BatchByPage, BatchByPageSize,sort,order, 0, rowData?.row?.original?.batch_number, keyWord)
    }

    const handleLotWiseCellClick = (rowData: any) => {
      if(rowData.column.id === "plates_used"){
        setVisibleTable("3")
        getActivityConcilation(activityWisePage, activityWisePageSize, rowData?.row?.original?.id, "lotId")
        setBatchId(rowData?.row?.original?.id)
      }
    }

    const getPlateList = async (page :number, size :number, batchId: any) => {
        try {
          const response = await PlateReconcilationApi.getPlateListByBatchId(page, size, batchId);
          if(response.status && response.statusCode === 200){
            try {
                if ( Array.isArray(response.data) && response.data?.length > 0) {
                  setPlateWiseTotalPages(response?.totalPages)
                  setPlateWiseTotalElements(response?.totalElements)
                  let rowData: any = response.data.map((element: any, index: number) => {

                    const statusNumber = parseInt(element.plate_status, 10); 
                    const plateStatus = userService.getPlateStatus(statusNumber);

                    return { 
                        id : element.id,
                        slno: index + 1, 
                        serial_number: element.serial_number,
                        plateStatus: plateStatus                        
                    };
                  });
                  setPlateDetails(rowData);
                }
              } catch (error) {
                console.log(error); 
              }
          } else {
            setPlateDetails([])
          }
        } catch (error) {
          console.error('Plate response', error);
        }
      };

    const getActivityConcilation = async (page: number, pageSize: number, id: any, type: string) => {
        setActivityConciliationType(type)
        try {
          let response
          if (type === "batchId") {
            response = await PlateReconcilationApi.getActivityConcilation(page, pageSize, id)
          } else if (type === "lotId") {
            response = await PlateReconcilationApi.getActivityConcilationByLotId(page, pageSize, id)
          }

          if(response.status && response.statusCode === 200){
            try {
              if (response.data && response.data.lot_details && response.data.lot_details.length > 0) {
                  setActivityWiseTotalPages(response?.totalPages)
                  setActivityWiseTotalElements(response?.totalElements)
                  let rowData: any = response.data.lot_details.map((element: any, index: number) => {
      
                    return { 
                        id : element.id,
                        slno: index + 1, 
                        lot_code: element.lot_code,
                        plate_sampling: element.activity_wise_reconciliation.sampling_plate_count,
                        air_sampling: element.activity_wise_reconciliation.active_air_sampling_plate_count,
                        passive_sampling: element.activity_wise_reconciliation.passive_air_sampling_plate_count,

                        sample_type_test: element.activity_wise_reconciliation.sampling_type_test_count,

                        surface_monitoring: element.activity_wise_reconciliation.sf_monitoring_plate_count,
                        personnel_monitoring: element.activity_wise_reconciliation.personal_monitoring_plate_count,
                        swab_collection: element.activity_wise_reconciliation.sf_monitoring_swab_plate_count,
                        visual_inspection_failed: element.activity_wise_reconciliation.visual_inspection_failed_plate_count,
                        plate_rejected: element.activity_wise_reconciliation.rejected_plate_count,
                        plate_discarded: element.activity_wise_reconciliation.discarded_plate_count,
                        exception_added: element.activity_wise_reconciliation.exception_added_plate_count,
                       
                    };
                  });
                  setActivityWiseList(rowData);
                }
              } catch (error) {
                console.log(error); 
              }
          } else {
            setActivityWiseList([])
          }
        } catch (error) {
          console.error('Plate response', error);
        }
      
    }

    const plateWiseReconciliationClose = () => {
      setPlateWisePage(1);
      setPlateWisePageSize(5);
      setPlateWiseTotalPages(0);
      setPlateWiseTotalElements(0);
      setVisibleTable("1")
      setBatchId("")
    }

    const activityWiseReconciliationClose = () => {
      setActivityWisePage(1);
      setActivityWisePageSize(5);
      setActivityWiseTotalPages(0);
      setActivityWiseTotalElements(0);
      setBatchId("")
      if (activityConciliationType === "batchId") {
        setVisibleTable("1")
      } else if (activityConciliationType === "lotId") {
        setVisibleTable("2")
      }
    }

    const lotWiseReconciliationClose = () => {
      setLotWisePage(1);
      setLotWisePageSize(5);
      setLotWiseTotalPages(0);
      setLotWiseTotalElements(0);
      setVisibleTable("1")
      setBatchId("")
    }

    useEffect (() => {
        listPlateBatch()
        getBatchList(selectedBatch)
        getDropLotList()
        listSerialNumber()
      }, [])

    // useEffect (() =>{
    //   getLotList(lotid)
    //   setLotCallFlag(false)
    // },[lotCallFlag])
    
    
    useEffect (() =>{
      getBatchList(selectedBatch)
      setUseEffectRequired(false)
    },[useEffectRequired])
    

    const handlebatchWiseReconciliation = (key: string, value: any) => {
       if (key === "batch_code") {
        getBatchList(value)
    };
    }

    const listLotNumber = async (batchNo: string) => {
      try {
        const response = await visualInspection.listFilterLots(batchNo);
        if(response.status && response.statusCode === 200){
          try {
              if ( Array.isArray(response.data) && response.data?.length > 0) {
                let rowData: any = response.data.map((element: any, index: number) => {
  
                  return { 
                      value: element.lot_code,
                      label: element.lot_code ,
                  };
                });
                setLotNum(rowData);
              }
            } catch (error) {
              console.log(error); 
            }
        }
      } catch (error) {
        console.error('Plate response', error);
      }
    }

  return (
    <div>
        <HeaderSection />
        <PageHeader pageHeader={t('reconciliationSummary.reconciliationSummary')} />
       
        {/* Batch-Wise Reconciliation */}
        {visibleTable === "1" && (
          <>
            <div className=" mx-3 pe-2" style={{"height":"45vh"}}>
                <CustomTable
                tableHead={t('reconciliationSummary.batchWiseReconciliation')}
                data={batchlist}
                columns={BatchWiseReconcilationColums}
                isEditable={false}
                totalElements={totalElements}
                isActionVisible={false}
                isViewVisible={false}
                addPlateUploadText={t('plateInventory.bulk')}
                blueBtnText='Plate Sampling'
                plateBatch={plateBatches}
                isSeachable={false}
                showPlateStatus={false}
                onCellClick={handleCellClick}
                showBatchFilter={true}
                onFilterClick={handlebatchWiseReconciliation}
                />
            </div>

          <div>
            <Pagination
                page={page}
                totalPages={totalPages}
                handlePageChange={handlePageChange}
              />
          </div>
          </>
          )}


          {/* Lot-Wise Reconciliation */}
          {visibleTable === "2" && (
            <>
            <div className=" mx-3 pe-2" style={{"height":"45vh"}}>
                <CustomTable
                tableHead={t('reconciliationSummary.lotWiseReconciliation')}
                data={lotList}
                columns={LotWiseReconcilationColums}
                isEditable={false}
                totalElements={lotWiseTotalElements}
                isActionVisible={false}
                isViewVisible={false}
                addPlateUploadText={t('plateInventory.bulk')}
                blueBtnText='Plate Sampling'
                plateBatch={plateBatches}
                lotCodeNumber={lotNum}
                isSeachable={false}
                showPlateStatus={false}
                onCellClick={handleLotWiseCellClick}
                buttonWithCloseIcon={true}
                buttonWithCloseIconText='cancel'
                onCloseBtnClick={() => lotWiseReconciliationClose()}
                />
            </div>
            <div>
                <Pagination
                    page={lotWisePage}
                    totalPages={lotWiseTotalPages}
                    handlePageChange={handleLotWisePageChangeLot}
                />
            </div>
            </>
          )}

          {/* Activity-Wise Reconciliation */}
          {visibleTable === "3" && (
            <>
            <div className=" mx-3 pe-2" style={{"height":"45vh"}}>
                <CustomTable
                tableHead={t('reconciliationSummary.activityWiseReconciliation')}
                data={activityWiseList}
                columns={ActivityWiseReconcilationColums}
                isEditable={false}
                totalElements={activityWiseTotalElements}
                isActionVisible={false}
                isViewVisible={false}
                addPlateUploadText={t('plateInventory.bulk')}             
                blueBtnText='Plate Sampling'  
                plateBatch={plateBatches}
                isSeachable={false}
                showPlateStatus={false}
                buttonWithCloseIcon={true}
                buttonWithCloseIconText='cancel'
                lotCodeNumber={lotNum}
                onCloseBtnClick={() => activityWiseReconciliationClose()}
                />
            </div>
            <div>
                <Pagination
                    page={activityWisePage}
                    totalPages={activityWiseTotalPages}
                    handlePageChange={handleActivityWisePageChange}
                />
            </div>
            </>
            )}

          {/* Plate-Wise Reconciliation */}
          {visibleTable === "4" && (
            <>
            <div className=" mx-3 pe-2" style={{"height":"45vh"}}>
                <CustomTable
                tableHead={t('reconciliationSummary.plateWiseReconciliation')}
                data={plateDetails}
                columns={PlateWiseReconcilationColums}
                isEditable={false}
                totalElements={PlateWiseTotalElements}
                isActionVisible={false}
                isViewVisible={false}
                //   addButton={permission?.includes("add_plates")}
                //   addButtonText={t('plateInventory.fileUpload')}
                //   onAddClick={onAddClick}
                //   addPlateUploadBtn={permission?.includes("add_plates")}
                addPlateUploadText={t('plateInventory.bulk')}
                //   showBlueBtn={permission?.includes("plate_sampling_test")}
                blueBtnText='Plate Sampling'
                //   onBlueBtnClick={handlePlateSampleClick}
                //   onUploadClick={onUploadClick}
                //   plateSerialNumber={plateSerielNum}
                plateBatch={plateBatches}
                isSeachable={false}
                showPlateStatus={false}
                //   onFilterClick={handleFilter}
                // isQrDownloadable={true}
                // onQrDownloadClick={handleQrDownload}
                //   onSortClick={handleSort}
                // onCellClick={handleCellClick}
                buttonWithCloseIcon={true}
                buttonWithCloseIconText='cancel'
                onCloseBtnClick={() => plateWiseReconciliationClose()}
                />
            </div>
            <div>
                <Pagination
                    page={plateWisePage}
                    totalPages={PlateWiseTotalPages}
                    handlePageChange={handlePlateWisePageChange}
                />
            </div>
            </>
            )}
    </div>
  )
}

export default PlateReconcilationSummary;