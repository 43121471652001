import React,{useEffect, useRef, useState} from 'react'
import CountdownTimer from '../../../components/common/countDownTimer';
import { FaTimes } from 'react-icons/fa';
import { IoMdDownload } from 'react-icons/io';




interface TableButtonSecProps {
    selectedRows: string[];
    tableHead: string;
    totalElements: number;
    startButton?: boolean;
    startButtonText?: string;
    endButton?: boolean;
    endButtonText?: string;
    addButton?: boolean;
    addButtonText?: string;
    buttonWithCloseIcon?: boolean;
    buttonWithCloseIconText?: string;
    discardButton?: boolean;
    discardButtonText?: string;
    approveButton?: boolean;
    approveButtonText?: string;
    rejectButton?: boolean;
    rejectButtonText?: string;
    changeRoleButton?:boolean;
    changeRoleButtonText?:string;
    actionCheckOrangeButton?: boolean;
    actionCheckOrangeBtnText?: string;
    addPlateUploadText?:string;
    addPlateUploadBtn?:boolean;
    showBlueBtn?:boolean;
    blueBtnText?:string;
    isQrDownloadable?:boolean;
    data: any[];
    onEditClick?: (id: string) => void;
    onCloseBtnClick?: () => void;
    setShowStartAlert: (value: boolean) => void;
    onQrDownloadClick?: (slno:string[], type:string) => void;
    setShowEndAlert: (value: boolean) => void;
    setShowApproveAlert: (value: boolean) => void;
    setShowRejectAlert: (value: boolean) => void;
    clearAllSelected?: () => void;
    handleAddButton?: () => void;
    handleActionCheckOrangeButton?: (slno:string[]) => void;
    handleDiscardButton?: () => void;
    handleUploadButton?:() => void;
    handleBlueBtnClick?:() => void;
    handleRoleDepartment?: () => void;
    
}




const TableButtonSec: React.FC<TableButtonSecProps> = ({
    selectedRows,
    tableHead,
    totalElements,
    startButton,
    startButtonText,
    endButton,
    endButtonText,
    addButton,
    addButtonText,
    discardButton,
    discardButtonText,
    approveButton,
    approveButtonText,
    rejectButton,
    rejectButtonText,
    changeRoleButton,
    changeRoleButtonText,
    actionCheckOrangeButton,
    actionCheckOrangeBtnText,
    addPlateUploadBtn,
    data,
    addPlateUploadText,
    showBlueBtn,
    blueBtnText,
    buttonWithCloseIcon,
    buttonWithCloseIconText,
    isQrDownloadable,
    setShowStartAlert,
    onQrDownloadClick,
    setShowEndAlert,
    setShowApproveAlert,
    setShowRejectAlert,
    clearAllSelected,
    handleAddButton,
    handleActionCheckOrangeButton,
    handleUploadButton,
    handleBlueBtnClick,
    handleDiscardButton,
    handleRoleDepartment,
    onCloseBtnClick
}) => {


    const [showEndButton, setShowEndButton] = useState<boolean>(false);

    const filteredItem : any = data.filter((item: any) => {
        return item.slno === selectedRows[0];
      });
    var result = filteredItem[0]?.remainingTime
    const pRef = useRef<HTMLParagraphElement | null>(null);

    useEffect(() => {
      if(endButtonText === "End Autoclave"){
        setShowEndButton(true)
      }
      if (pRef.current && pRef.current.textContent && pRef.current.textContent.includes('Over Due')) {
        setShowEndButton(true)
      } else if(endButtonText === "End Incubation") {
        setShowEndButton(false)
      }
    }, [pRef?.current?.textContent, result]);

  return (
    <>
        {selectedRows.length > 0 ? (
          <div className="d-flex">
              <p className='boldText1 pt-1 '>Selected | {selectedRows.length}  </p>
        
              {startButton && filteredItem[0]?.status === 'Pending' && (
                <div className='d-flex'>
                    <button onClick={() => setShowStartAlert(true)} className="approveLabReportBtn mx-2" >{startButtonText}</button>
                </div>
              )}

              {endButton && filteredItem[0]?.status.includes('In Progress') && showEndButton && (
                <button onClick={() => setShowEndAlert(true)} className="rejectLabReportBtn mx-2">
                  {endButtonText}
                </button>
              )}
              <p hidden ref={pRef}>{result}</p>

              {/* <CountdownTimer endTime={endIncubationTime} /> */}

              {approveButton && (
                <div className='d-flex'>
                    <button onClick={() => setShowApproveAlert(true)} className="approveLabReportBtn mx-2" >{approveButtonText}</button>
                </div>
              )}
              
              {rejectButton  && (
                <div className='d-flex'>
                    <button onClick={() => setShowRejectAlert(true)} className="rejectLabReportBtn mx-2" >{rejectButtonText}</button>
                </div>
              )}

               {changeRoleButton  && data[0]?.status && (
                <div className='d-flex'>
                    <button onClick={handleRoleDepartment} className="approveLabReportBtn mx-2" >{changeRoleButtonText}</button>
                </div>
              )}

              {isQrDownloadable && (
                <div className='d-flex'>
                    <button 
                      onClick={() => onQrDownloadClick && onQrDownloadClick(selectedRows, "multi")} 
                      className="approveLabReportBtn mx-2 px-2" >
                        QR<IoMdDownload className='ms-1' fontSize={15} />
                    </button>
                </div>
              )}

              {actionCheckOrangeButton && (
                <div className='d-flex'>
                    <button className="squareOrangeBtn2 mx-3 mb-2" onClick={() => handleActionCheckOrangeButton && handleActionCheckOrangeButton(selectedRows)} >{actionCheckOrangeBtnText}</button>
                </div>
              )}

              <button onClick={clearAllSelected} className="clearAllBtn">Clear All</button>
          </div>
        ) : tableHead && (
            <div className='d-flex'>
            <p className='boldText1 pt-1 '>{tableHead} | {totalElements} </p>

            {approveButton && data[0]?.status && (
                <div className='d-flex'>
                    <button 
                      className="approveLabReportBtn mx-2"
                      title="Select plate to approve"
                      style={{"opacity":"0.5"}} >{approveButtonText}
                    </button>
                </div>
              )}
              
            {rejectButton  && data[0]?.status && (
              <div className='d-flex'>
                  <button 
                    className="rejectLabReportBtn mx-2"
                    title="Select plate to reject" 
                    style={{"opacity":"0.5"}}>
                      {rejectButtonText}
                  </button>
              </div>
            )}

            {changeRoleButton  && data[0]?.status && (
                <div className='d-flex'>
                    <button 
                      title="Select user to change role" 
                      style={{"opacity":"0.5"}} 
                      className="approveLabReportBtn mx-2" >
                        {changeRoleButtonText}
                    </button>
                </div>
            )}

            {isQrDownloadable && (
                <div className='d-flex'>
                    <button 
                      title="Select an item to download QR code" 
                      className="approveLabReportBtn mx-2 px-2"
                      style={{"opacity":"0.5"}} >
                        QR <IoMdDownload className='ms-1' fontSize={15} />
                    </button>
                </div>
            )}

            {actionCheckOrangeButton && (
                <div className='d-flex'>
                    <button className="squareOrangeBtn2 mx-3 mb-2" style={{"opacity":"0.5"}} title="Select item(s) to navigate">{actionCheckOrangeBtnText}</button>
                </div>
              )}
            
            {/* updated */}
            {addButton && (
                <div className='d-flex'>
                    <button className="squareOrangeBtn2 mx-3 mb-2" onClick={handleAddButton} >{addButtonText}</button>
                </div>
            )}

            {discardButton && (
                <div className='d-flex'>
                    <button className="rejectLabReportBtn mx-3 mb-2" onClick={handleDiscardButton} >{discardButtonText}</button>
                </div>
            )}

             {addPlateUploadBtn && (
                <div className='d-flex'>
                    <button className="rejectLabReportBtn  mb-2 mx-2" onClick={handleUploadButton} >{addPlateUploadText}</button>
                </div>
            )}

           {showBlueBtn && (
                <div className='d-flex'>
                    <button className="blueTableBtn mx-2 mb-2" onClick={handleBlueBtnClick} >{blueBtnText}</button>

                </div>
            )}



            {buttonWithCloseIcon && (
                <div className='d-flex'>
                    <button 
                      className="btnWithClose mx-3 mb-2 px-3" 
                     >
                      {buttonWithCloseIconText}
                      <FaTimes onClick={() => onCloseBtnClick && onCloseBtnClick()} className='ms-3' fontSize={17} fill='red' />
                    </button>
                </div>
            )}

            </div>
        )}
    </>
  )
}

export default TableButtonSec