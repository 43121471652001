export const HolidayNamecolumns: TableColumn[] = [
    {
        Header: "Sl no",
        accessor: "slno",
      },
      {
        Header: " Shift Name",
        accessor: "shift_name",
      },
      {
        Header: "Start Time",
        accessor: "start_time",
      },
      {
        Header: "End Time",
        accessor: "end_time",
      },
      {
        Header: "Duration (hrs)",
        accessor: "duration",
      },

]

export const columns: TableColumn[] = [
  {
      Header: "Sl no",
      accessor: "slno",
    },
    {
        Header: " Shift Name",
        accessor: "shift_name",
    },
    {
        Header: "Start Time",
        accessor: "start_time",
    },
    {
        Header: "End Time",
        accessor: "end_time",
    },
    {
        Header: "Assigned Operator",
        accessor: "assigned_operator",
    },
]


interface TableColumn {
    Header: string;
    accessor: string;
  }