import React, { useEffect, useRef, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';
import { TfiClose } from 'react-icons/tfi';
import { IoSearchSharp } from "react-icons/io5";
import { userService } from '../../../service/plateServices';
import { CiCircleRemove } from 'react-icons/ci';
import { IncidentOrDeviationApi } from '../../../service/deviation';
import AlertModal from '../../../widgets/alertModal';
import CustomToast from '../../../widgets/toaster';


interface AddExceptionModelProps {
    show: boolean;
    setShow: () => void;
    reason: any
    setUseEffectRequired: (value : boolean) => void;

}


const AddDeviationModel: React.FC<AddExceptionModelProps> = ({
    show,
    setShow,
    reason,
    setUseEffectRequired
}) => {

    // const [show, setShow] = useState(false);
    const { t } = useTranslation();
    const scanAreaRef = useRef<HTMLInputElement>(null);


    const [scanCode, setScanCode] = useState("");
    const [plateBySerielNo, setPlateBySerielNo] = useState<Array<any>>([]);
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState("success");
    const [toastType, setToastType] = useState("success");
    const [selectedSerielNo, setSelectedSerielNo] = useState("");
    const [addAreaData, setAddAreaData] = useState({
      id: "",
      area_code: "",
      name: "",
    });
    const [deviationAlert, AddDeviationAlert] = useState(false);
    const [formData, setFormData] = useState({
      reason: '',
      remarks: '',
    });
     //handle error section
    interface Errors {
      equipmentCode?: string;
      reason?: string;
    }
    const [errors, setErrors] = useState<Errors>({});

    const getAreaById = async (Id : string) => {
      try {
        const response = await IncidentOrDeviationApi.getAreaId(Id);
        if(response.status && response.statusCode === 200){
            setAddAreaData({
              id : response.data.id,
              area_code: response.data.area_code,
              name: response.data.name,
            });
        }
      } catch (error) {
        console.error('user response', error);
      }
    }

    const getAreaByLocationCode = async (Id : string) => {
      try {
        const response = await IncidentOrDeviationApi.getAreaByLocationCode(Id);
        if(response.status && response.statusCode === 200){
            setAddAreaData({
              id : response.data.id,
              area_code: response.data.area_code,
              name: response.data.name,
            });
        }
      } catch (error) {
        console.error('user response', error);
      }
    }



    //pass the obtained serial number to the api 
    const handleScanSerielNo = (result: any) => {
        console.log('Original Result:', result);
        if (result.trim() !== "") {
          try {
            const parsedResult = JSON.parse(result);
            console.log('Parsed Result:', parsedResult);
            setScanCode(parsedResult.code);
      
            if (typeof parsedResult === 'object') {
              let serialNumber;
      
              if (parsedResult.hasOwnProperty('id')) {
                serialNumber = parsedResult.id;
              } else {
               
                console.log('No serial number property found in the parsed result.');
              }
              if (serialNumber) {
                getAreaById(serialNumber);
                // setScanCode(serialNumber)
              }
            } else {
              console.log('Parsed result is not an object.');    
            }
          } catch (error) {
            // getAreaById(result);
            console.error('Error parsing JSON:', error);
          }
        }
      };

    //get plate details by serial number on add exception 
    // const getPlateDetailsBySerielNo = async (slNo : string) => {
    //     let process = "test"
    //     if(slNo !== ""){
    //       console.log("have slno")
    //       try {
    //         const response = await userService.listAutoClavePlateBySerielNo(slNo, process);
    //         if(response.status && response.statusCode === 200){
    //           try {
    //               // if ( Array.isArray(response.data) && response.data?.length > 0) {
    //               //   let rowData: any = response.data.map((element: any, index: number) => {
    //               //     return { 
    //               //         plateSerielId: element.id ,
    //               //         plateType: userService.getPlateStatus(parseInt(element.plate_status)) ,
    //               //         batchId:element.batch_number ,
    //               //         plateSerielNo: element.serial_number ,
    //               //     };
    //               //   });
    //               if (response.data && typeof response.data === 'object') {
    //                 let rowData = [{
    //                     slno: 1,
    //                     plateSerielId: response.data.id,
    //                     plate_type: userService.getPlateStatus(parseInt(response.data.plate_status)),
    //                     batch_number: response.data.batch_number,
    //                     serial_number: response.data.serial_number,
    //                     lot_pass_count: 0,
    //                 }];
    //                 // setPlateBySerielNo(rowData);

    //                 const startingSlno = plateBySerielNo.length + 1;
    //                 rowData = rowData.map((item, index) => ({
    //                     ...item,
    //                     slno: startingSlno + index
    //                 }));

    //                 const uniqueUpdatedDataWithSlno = rowData.filter((newElement : any) => {
    //                   return !plateBySerielNo.some((existingElement) => existingElement.serial_number === newElement.serial_number);
    //                 });
  
    //                 setPlateBySerielNo((prevUserRoleList) => [...prevUserRoleList, ...uniqueUpdatedDataWithSlno]);
    //               }
    //             } catch (error) {
    //               console.log(error); 
    //             }
    //         } else {
    //           // setPlateBySerielNo([]);
    //           setToastMessage(response.message);
    //           setToastType("error")
    //           setShowToast(true)
    //         }
    //       } catch (error) {
    //         console.error('Plate response', error);
    //       }
    //     } else {
    //     console.log("no slno")
    //     }
    //   }

    const handleCancel = () => {
      setAddAreaData({
        id: "",
        area_code: "",
        name: "",
      })
      setScanCode("")
      setSelectedSerielNo("")
      setShow();
      setFormData({
        reason: '',
        remarks: '',
      })
      setErrors({});
    }

    const handleSubmit = async () => {
      const dataToSend = {
        "location_id": addAreaData.id,
        "reason_id": formData.reason,  
        "remarks": formData.remarks,
      }

      try {
        const response = await IncidentOrDeviationApi.SaveDeviation(dataToSend);
        if (response.status && response.statusCode === 200) {
          setToastMessage(response.message)
          setToastType("success")
          handleCancel();
          setShowToast(true)
          setUseEffectRequired(true)
        } else {
          setToastMessage(response.message)
          setToastType("error")
        }
        // setShowToast(true)
      
        AddDeviationAlert(false)
      } catch (error) {
        console.error("user response", error);
      }
    }
    

    const handleErrorSubmit = () => {
      const errors: Errors = {};
      if (!addAreaData.area_code) {
        errors.equipmentCode = "Please Scan / Search - Area / Location / Equipment QR Code";
      }
      if (!formData.reason) {
        errors.reason = "Please Select a Reason";
      }

      if (Object.keys(errors).length > 0) {
        setErrors(errors);
        return;
      }

      AddDeviationAlert(true)
      setErrors({});
    }

    const handleChange = (e : any) => {
      const { name, value } = e.target;
      setFormData({
        ...formData,
        [name]: value
      });
    };

    const checkSpecialChar =(e : any)=>{
      if(!/[0-9a-zA-Z]/.test(e.key)){
       e.preventDefault();
      }
     };

    useEffect(() => {
      if (show === true && scanAreaRef.current) {
        scanAreaRef.current.focus();
      }
    }, [show])

  return (
    <>
    <CustomToast
        show={showToast}
        onClose={() => setShowToast(false)}
        delay={5000}
        message={toastMessage}
        toastType={toastType}
      />

    <Modal 
        className="top-right-modal"
        centered={false}
        show={show}
        size="lg"
    >
        <div>
            <div className="modalHead">
            <p className="modalHeadText ms-3 pt-3 p-2">
                Add Deviation / Incident
            </p>
            <div onClick={() => handleCancel()} className="p-2 mx-3 my-2 ">
                <TfiClose fill="#FFFFFF" />
            </div>
            </div>

            <div className='d-flex'>


            <div className="scanWrap pt-3" style={{"width": "45%"}}>
                <label style={{"display": "flex"}}>Scan Area / Location / Equipment QR Code
                <span style={{ color: "red" }}> *</span>
                </label>
                <div className="scanInputWrap p-2">
                <input
                    onChange={(e) => handleScanSerielNo(e.target.value)}
                    placeholder="Scan Only"
                    value={scanCode}
                    ref={scanAreaRef}
                />
                 {scanCode && (
                    <CiCircleRemove 
                    onClick={() => setScanCode("")} 
                    fontSize={30} 
                    color="red"
                    className="mt-1"
                    style={{"cursor": "pointer"}}
                    />
                )}
                </div>

                {errors.equipmentCode && <p className="errorText">{errors.equipmentCode}</p>}
            </div>

            <div className="scanWrap pt-3" style={{"width": "55%"}}>
                <label style={{"display": "flex"}}>Search Area / Location / Equipment QR Code
                <span style={{ color: "red" }}> *</span>
                </label>
                <div className="scanInputWrap p-2">
                <input
                    onChange={(e) => setSelectedSerielNo(e.target.value)}
                    placeholder="Type & Search"
                    onKeyDown={(e)=>checkSpecialChar(e)}
                />
                < IoSearchSharp 
                    fontSize={25} 
                    className='mt-1' 
                    color='grey'
                    style={{"cursor":"pointer"}} 
                    onClick={() => getAreaByLocationCode(selectedSerielNo)}
                />
                </div>
            </div>

            </div>


            <div className="selectWrap">
                <div className="form-group my-1 mx-5 px-3 ">
                        <label htmlFor="" >
                            Area / Location / Equipment Code
                        </label>
                        <br />
                        <input 
                            style={{"width": "150%", "fontSize":"12px"}}
                            className='scanInputWrap ps-1' 
                            type='text'
                            placeholder='Area / Location / Equipment Code'
                            value={addAreaData.area_code}
                            disabled
                        />
                </div>
                <div className="form-group my-1 mx-5" style={{"marginRight":"250px", paddingLeft: "11rem"}}>
                        <label htmlFor="">
                            Area / Location / Equipment Name
                        </label>
                        <br />
                        <input 
                            style={{"width": "150%", "fontSize":"12px"}}
                            className='scanInputWrap ps-1' 
                            type='text'
                            placeholder='Area / Location / Equipment Name'
                            value={addAreaData.name}
                            disabled
                        />
                </div>
                <div className="form-group ms-2 ps-1 " style={{"visibility": "hidden"}}>
                        <label htmlFor="">
                        {t("Exception.remarks")}
                        </label>
                        <br />
                        <textarea
                        placeholder="Remarks"
                        className="otherReasonTextArea p-2"
                        name="remarks"
                        // value={formData.remarks}
                        // onChange={handleChange}
                        />
                        {/* {errors.remarks && (
                        <p className="errorText m-0 p-0">{errors.remarks}</p>
                        )} */}
                 </div> 
            </div>

            <div className="selectWrap">
                <div className="form-group my-1 mx-5 px-3">
                <label htmlFor="">
                        {t("Exception.reason")}<span style={{ color: "red" }}> *</span>
                        </label>
                        <br />
                        <select
                        name="reason"
                        value={formData.reason}
                        onChange={handleChange}
                        >
                        <option value="">Select</option>
                        {reason.map((obj : any, index : any) => (
                        <option key={index} value={obj?.id}>
                          {obj?.reason}
                        </option>
                         ))}
                        </select>
                        {errors.reason && (
                        <p className="errorText m-0 p-0">{errors.reason}</p>
                        )}
                </div>
                <div className="form-group my-1 mx-2" style={{"visibility": "hidden"}} >
                        <label htmlFor="">
                        Test Result
                        </label>
                        <br />
                        <select
                        name="plate_inspection_status"
                        //   value={formData.plate_inspection_status}
                        //   onChange={handleChange}
                        >
                        <option value="">Select</option>
                        <option value="passed">passed</option>
                        <option value="failed">failed</option>
                        </select>
                </div>
                <div className="form-group ms-2 ps-1" style={{"marginRight":"220px"}}>
                    <label htmlFor="">
                      {t("Exception.remarks")}
                    </label>
                    <br />
                    <textarea
                      placeholder="Remarks"
                      className="otherReasonTextArea p-2"
                      name="remarks"
                      value={formData.remarks}
                      onChange={handleChange}
                      onKeyDown={(e)=>checkSpecialChar(e)}
                    />
                    {/* {errors.remarks && (
                      <p className="errorText m-0 p-0">{errors.remarks}</p>
                    )} */}
                </div>
            </div>

       
            <div className="bottomArea">
                <button
                    onClick={() => handleCancel()}
                    className="modalCancelBtn"
                >
                    {t("buttons.cancel")}
                </button>
                <button
                    onClick={() => handleErrorSubmit()}
                    className="squareOrangeBtn3"
                >
                    {t("buttons.submit")}
                </button>
                {/* <button className=' squareOrangeBtn3Disabled'   onClick={() => handleErrorSubmit()}>Submit</button> */}
            </div>


        </div>
    </Modal>

    <AlertModal
        show={deviationAlert}
        onHide={() => AddDeviationAlert(false)}
        title="Alert"
        message={`Add Deviation`}
        onConfirm={handleSubmit}
      />
    </>
    
    
  )
}

export default AddDeviationModel;