import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import React, { useState, ChangeEvent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { areaService } from '../../../../service/areaServices';
import {useFilter} from '../partials/FilterContext'

interface DownloadPDFTabProps {
  printDivId: string; 
  showFilters?: boolean;
  onApplyFilters?: (filters: any) => void;
}

const DownloadPDFTab: React.FC<DownloadPDFTabProps> = ({
  printDivId,
  showFilters,
  onApplyFilters,
}) => {
  const { setFilters } = useFilter();
  const { t } = useTranslation();
  const[plantArea , setPlantArea] = useState<Array<any>>([]); 
  const[unitArea , setUnitArea] = useState<Array<any>>([]); 
  const[BuildingArea , setBuildingArea] = useState<Array<any>>([]); 
  const[areas , setAreas] = useState<Array<any>>([]); 
  const [addAreaData, setAddAreaData] = useState({
    plant: "",
    unit : "",
    building : "",
    parentArea: "",
    selectedDate: "",
    endDate : ""
  });
  // const handleDateSelect = (date: string) => {
  //   if (setSelectedDate) {
  //       setSelectedDate(date);
  //     }
  // };

  // const setEndDate = (date: string) => {
  //   if(setSelectedEndDate) {
  //     setSelectedEndDate(date);
  //   }
  // }


     //api call for plant area list in add modal 
     const getPlantAreaList = async () => {
      try {
        const response = await areaService.getAddAreaType("1");
          if(response.status && response.statusCode === 200){
            try {
                if ( Array.isArray(response.data) && response.data?.length > 0) {
                  let rowData: any = response.data.map((element: any, index: number) => {

                    return { 
                        slno: index + 1, 
                        name: element.name,
                        id : element.id,
                        type: element.type,
                        status : element.status,
                    };
                  });
                  setPlantArea(rowData);
                }
              } catch (error) {
                console.log(error);
              }
          }
      } catch (error) {
        console.error('user response', error);
      }
    }

    //api call for unit area list in add modal 
    const getUnitAreaList = async () => {
      try {
        if (addAreaData.plant !== "") {
        const response = await areaService.getHeirarchyType("2" , addAreaData.plant);
          if(response.status && response.statusCode === 200){
            try {
                if ( Array.isArray(response.data) && response.data?.length > 0) {
                  let rowData: any = response.data.map((element: any, index: number) => {

                    return { 
                        slno: index + 1, 
                        name: element.name,
                        id : element.id,
                        type: element.type,
                        status : element.status,
                    };
                  });
                  setUnitArea(rowData);
                }
              } catch (error) {
                console.log(error);
              }
          }
        }
      } catch (error) {
        console.error('user response', error);
      }
    }

    //api call for building area list in add modal 
    const getBuildingAreaList = async () => {
      try {
        if (addAreaData.unit !== "") {
        const response = await areaService.getHeirarchyType("3" , addAreaData.unit);
          if(response.status && response.statusCode === 200){
            try {
                if ( Array.isArray(response.data) && response.data?.length > 0) {
                  let rowData: any = response.data.map((element: any, index: number) => {

                    return { 
                        slno: index + 1, 
                        name: element.name,
                        id : element.id,
                        type: element.type,
                        status : element.status,
                    };
                  });
                  setBuildingArea(rowData);
                }
              } catch (error) {
                console.log(error);
              }
          }
        }
      } catch (error) {
        console.error('user response', error);
      }
    }

    //get parent area based on selected block
    const getParentAreaList = async () => {
        try {
          if (addAreaData.building !== "") {
          const response = await areaService.getHeirarchyType("6" , addAreaData.building);
            if(response.status && response.statusCode === 200){
              try {
                  if ( Array.isArray(response.data) && response.data?.length > 0) {
                    let rowData: any = response.data.map((element: any, index: number) => {
  
                      return { 
                          slno: index + 1, 
                          name: element.name,
                          id : element.id,
                          type: element.type,
                          status : element.status,
                      };
                    });
                    setAreas(rowData);
                  }
                } catch (error) {
                  console.log(error);
                }
            }
          }
        } catch (error) {
          console.error('user response', error);
        }
    }

    useEffect(() =>{
      setUnitArea([])
      getUnitAreaList();
    },[addAreaData.plant])

    useEffect(()=>{
      setBuildingArea([])
      getBuildingAreaList();
    },[addAreaData.unit])

    useEffect(()=>{
      setAreas([])
      getParentAreaList();
    },[addAreaData.building])

    useEffect(() =>{
      getPlantAreaList();
      handleFilterAlert();
    },[])

    //handle onchange input fields  
    const handleAddAreaChange = (e : any) => {
      const { name, value } = e.target;
      setAddAreaData((prevFormData : any) => ({
        ...prevFormData,
        [name]: value,
      }));
    }

    const handleFilterAlert = () => {
      const filters = {
        plant: addAreaData.plant || "",
        unit: addAreaData.unit || "",
        building: addAreaData.building || "",
        parentArea: addAreaData.parentArea || "",
        startDate: addAreaData.selectedDate || null,
        endDate: addAreaData.endDate || null,
      };
    
      // Replace undefined values with empty strings
      // const filtersWithDefaults = Object.fromEntries(
      //   Object.entries(filters).map(([key, value]) => [key, value || ""])
      // );
    
      // setFilters((prevFilters) => ({ ...prevFilters, ...filtersWithDefaults }));
      const filtersWithDefaults = Object.fromEntries(
        Object.entries(filters).map(([key, value]) => [
          key,
          key.includes("Date") && value === undefined ? null : value || "",
        ])
      );
      setFilters((prevFilters) => ({ ...prevFilters, ...filtersWithDefaults }));
    };

    const formatDateTime = (dateString: string) => {
      const date = new Date(dateString);
      const formattedDate =
        `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-` +
        `${date.getDate().toString().padStart(2, '0')} ` +
        `${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}:${date.getSeconds().toString().padStart(2, '0')}`;
    
      return formattedDate;
    };

  return (
    <div className='dashTopTab px-3'>
      {showFilters && (
        <div className="selectWrapFilter">
          <div className="form-group" >
                <label>{t('common.plant')}</label>
                <br />
                <select
                  id="plant"
                  value={addAreaData.plant}
                  name="plant"
                  onChange={handleAddAreaChange} 
                >
                  <option value="">Select</option>
                  {plantArea.map((plant) => (
                    <option key={plant.id} value={plant.id}>
                      {plant.name}
                    </option>
                   ))} 
                </select>
          </div>

          <div className="form-group mx-1">
                <label>{t('common.unit')}</label>
                <br />
                <select
                  id="unit"
                  value={addAreaData.unit}
                  name="unit"
                  onChange={handleAddAreaChange} 
                >
                  <option value="">Select</option>
                  {unitArea.map((unit) => (
                    <option key={unit.id} value={unit.id}>
                      {unit.name}
                    </option>
                  ))}
                </select>
          </div>

          <div className="form-group">
                <label>{t('common.building_block')}</label>
                <br />
                <select
                  id="building"
                  value={addAreaData.building}
                  name="building"
                  onChange={handleAddAreaChange} 
                >
                  <option value="">Select</option>
                  {BuildingArea.map((parentArea) => (
                    <option key={parentArea.id} value={parentArea.id}>
                      {parentArea.name}
                    </option>
                  ))}
                </select>
          </div>
          <div className="form-group">
                <label>{t('common.area')}</label>
                <br />
                <select
                  id="parentArea"
                  value={addAreaData.parentArea}
                  name="parentArea"
                  onChange={handleAddAreaChange} 
                >
                  <option value="">Select</option>
                  {areas.map((parentArea) => (
                    <option key={parentArea.id} value={parentArea.id}>
                      {parentArea.name}
                    </option>
                  ))}
                </select>
          </div>
        </div>
      )}
      <div className="dashTopTabDate mx-1">
        <label className='me-1'>{t('common.start_date')}</label>
        <input
          id="selectedDate"
          className="form-control"
          value={formatDateTime(addAreaData.selectedDate)}
          name="selectedDate"
          type="datetime-local"
          onChange={(e) => {
            const formattedValue = e.target.value ? formatDateTime(e.target.value) : "";
            handleAddAreaChange({ target: { name: 'selectedDate', value: formattedValue } });
          }}
        />
      </div>
      <div className="dashTopTabDate mx-1">
        <label className='me-1'>{t('common.end_date')}</label>
        <input
          id="endDate"
          className="form-control"
          value={formatDateTime(addAreaData.endDate)}
          name="endDate"
          type="datetime-local"
          onChange={(e) => {
            const formattedValue = e.target.value ? formatDateTime(e.target.value) : "";
            handleAddAreaChange({ target: { name: 'endDate', value: formattedValue } });
          }}
        />
      </div>

      <div className='applyBtnWrap'>
        <button className='squareOrangeBtn3' style={{marginTop: "25px"}} onClick={handleFilterAlert} >{t('buttons.apply')}</button>
      </div>
    </div>
  );
};

export default DownloadPDFTab;
