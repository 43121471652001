import React, { useEffect, useState } from "react";
import HeaderSection from "../../../common/headerSection";
import PageHeader from "../../../common/pageHeader";
import CustomToast from "../../../../widgets/toaster";
import CustomTable from "../../../../widgets/table";
import Pagination from "../../../../widgets/pagination";
import { columns } from "./partials/columns";
import AlertModal from "../../../../widgets/alertModal";
import { userService } from "../../../../service/userService";
import { Modal } from 'react-bootstrap';
import { TfiClose } from "react-icons/tfi";
import { useTranslation } from "react-i18next";
import { gradeTypes } from "../../../../service/gradeType";



const  PersonnelMonitoringLocation= () => {
  const { t } = useTranslation();
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastType, setToastType] = useState("");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [totalPages, setTotalPages] = useState(0);
  const [totalElements, setTotalElements] = useState(0);
  const [useEffectRequired, setUseEffectRequired] = useState(false);
  const [rolesList, setrolesList] = useState<Array<any>>([]);
  const [useeffect , setUseEffect] = useState(false);
  const [statusAlert , setStatusAlert] = useState(false);
  const [gradeStatus, setGradeStatus] = useState("");
  const [updatedStatus, setUpdatedStatus] = useState("");
  const [modalName, setModalName] = useState("");
  const [permission, setPermission] = useState<any>([]);
  const [addUserData, setAddUserData] = useState({
    personnelLocName:"",
    locationCode: "",
    description: "",
    reason : "",
    id : "",
    status: ""
  });
  const [showAddModal, setShowAddModal] = useState(false)
  const [addStatusAlert , setAddStatusAlert] = useState(false);
  interface Errors {
    personnelLocName?: string,
    locationCode?: string,
    description?: string,
    reason?: string,
  }
  const [errors, setErrors] = useState<Errors>({});
  const [modalEdit, setModalEdit] = useState(false);

  const handlePageChange = (newPage: any, pageSize?: number) => {
    setPage(newPage);
    if (pageSize) {
      setPageSize(pageSize);
    }
    setUseEffectRequired(true);
  };

  useEffect(() => {
    var storedData = localStorage.getItem("userDetails");

    if (storedData) {
      var storedObject = JSON.parse(storedData);
      getPermissionByRole(storedObject[0].role_master_id);
    }

    listPersonnelLocList();
  }, []);

  const listPersonnelLocList = async () => {
    try {
      const response = await gradeTypes.listPersonnelLocList(page, pageSize);
      if (response.status && response.statusCode === 200) {
        try {
          if (Array.isArray(response.data) && response.data?.length > 0) {
            setTotalPages(response?.totalPages);
            setTotalElements(response?.totalElements);
            let rowData: any = response.data.map(
              (element: any, index: number) => {
                return {
                  slno: index + 1,
                  personnelLocName: element.option,
                  locationCode: element.location_code,
                  id: element.id,
                  status: element.status,
                  description: element.description,
                  reason : element.reason
                };
              }
            );
            setrolesList(rowData);
          }
        } catch (error) {
          console.log(error);
        }
      }
    } catch (error) {
      console.error("user response", error);
    }
  };

  useEffect(() => {
    listPersonnelLocList();
    setUseEffectRequired(false);
    setUseEffect(false)
  }, [useeffect, useEffectRequired]);


  const handleAddClick = () => {
    setShowAddModal(true)
    setModalName(t('personnelMonitoring.addPersonnelMonitoring'))
    setModalEdit(false)
  };

  const handleEditClick = (id: string) => {
    const filteredUserDetails = rolesList.filter((item) => item.slno === id);
    const selectedIds = filteredUserDetails.map((item) => item.id).join(',');
    setModalName(t('personnelMonitoring.editPersonnelMonitoring'))
    setModalEdit(true)
    setShowAddModal(true)
    setAddUserData({
    personnelLocName: filteredUserDetails[0]?.personnelLocName,
    locationCode: filteredUserDetails[0]?.locationCode,
    description: filteredUserDetails[0]?.description,
    reason: filteredUserDetails[0]?.reason,
    id: filteredUserDetails[0]?.id,
    status: filteredUserDetails[0]?.status
    })
  }


  const handleStatusChange = (area_id:any , status: string) => {
    setStatusAlert(true)
    setGradeStatus(area_id)
      if (status === "active") {
        setUpdatedStatus("inactive")
      } else {
        setUpdatedStatus("active")
      }
  }

  const handleStatusChangeSubmit = async () => {
    try {
      const response = await gradeTypes.changePersonnelLocationStatus(gradeStatus, updatedStatus , "Personnel Monitoring Location Status");
      
      if (response.status && response.statusCode === 200) {
        setToastType("success")
        setToastMessage(response.message)
      }
      else {
        setToastType("error")
        setToastMessage(response.message)
      }
      setShowToast(true)
      setUseEffectRequired(true)
      setUpdatedStatus("")
      setGradeStatus("")
      setStatusAlert(false)
    } catch (error) {
      console.error(error);
    }
  }

   //get permission by role 
   const getPermissionByRole = async (role : number) => {
    try {
      const response = await userService.getPermissionByRole(role);
      if(response.status && response.statusCode === 200){
        setPermission(response.data.permissions)
      }
    } catch (error) {
      console.error(error);
    }
  }


  const handleAddUserChange = (e : any) => {
    const { name, value } = e.target;

    let regex;
    if (name === "personnelLocName") {
        regex = /^[a-zA-Z0-9\s]*$/;
    } else if (name === "description") {
        regex = /^[a-zA-Z0-9\s]*$/;
    } else if (name === "locationCode"){
        regex = /^[a-zA-Z0-9\s]*$/;
    }

    if (value === "") {
      // console.log("haa122")
    } else if (regex && !regex.test(value)) {
      return
    }

    setAddUserData((prevFormData : any) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const checkSpecialChar =(e : any)=>{
    if(!/[0-9a-zA-Z]/.test(e.key)){
     e.preventDefault();
    }
   };


  const addGradeClose = () => {
    setShowAddModal(false)
    setErrors({})
    setAddUserData({
    personnelLocName:"",
    locationCode: "",
    description: "",
    reason : "",
    id : "",
    status : ""
    });
  }

  const handleUserAddAlert = () => {
    const errors: Errors = {};
   
    if (!addUserData.personnelLocName) {
      errors.personnelLocName = "Please Enter Location Name";
    }
    if (!addUserData.locationCode) {
      errors.locationCode = "Please Enter Location Code";
    }
    if (!addUserData.description) {
      errors.description = "Please Enter Description";
    }
    if (addUserData.id && !addUserData.reason) {
      errors.reason = "Please Enter Reason";
    }
    
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }
    setAddStatusAlert(true)
    setErrors({});
  }

  const handleGradeSubmit = async () => {
    setAddStatusAlert(false)
    const dataToSend = {
        "id":addUserData.id,
        "option":addUserData.personnelLocName,
        "location_code":addUserData.locationCode,
        "description" : addUserData.description,
        "interface_name":"Personnel Monitoring Location",
        "reason" : addUserData.reason
    }
    try {
      const response = await gradeTypes.addPersonnelMonitoringLoc(dataToSend);
      if(response.status && response.statusCode === 200){
        setToastType("success")
        setToastMessage(response.message)
        setShowToast(true)
        setAddStatusAlert(false)
        addGradeClose();
        setUseEffectRequired(true)
      } else {
        setToastType("error")
        setToastMessage(response.message)
        setShowToast(true)
        setAddStatusAlert(false)
  
      }
     
    } catch (error) {
      console.error('Plate response', error);
    }
  }

 
  return (
    <div>
      <HeaderSection />
      <PageHeader pageHeader={t('personnelMonitoring.addPersonnelMonitoring')} />
      <CustomToast
        show={showToast}
        onClose={() => setShowToast(false)}
        delay={5000}
        message={toastMessage}
        toastType={toastType}
      />

      <div className="me-3">
        <div className=" mx-3 pe-2" style={{ height: "45vh" }}>
          <CustomTable
            data={rolesList}
            columns={columns}
            isEditable={permission?.includes("edit_grade_type")}
            totalElements={totalElements}
            isActionVisible={false}
            isViewVisible={false}
            isSeachable={false}
            isToggleStatus={true}
            tableHead={t('personnelMonitoring.addPersonnelMonitoring')}
            showBatchFilter={false}
            showSerielNoFilter={false}
            addButton={permission?.includes("add_grade_type")}
            addButtonText={t('personnelMonitoring.addPersonnelMonitoring')}
            onAddClick={handleAddClick}
            onEditClick={handleEditClick}
            onStatusChange={handleStatusChange}
            onUseEffectRequired={() => setUseEffectRequired(true)}
          />
        </div>
        <div>
          <Pagination
            page={page}
            totalPages={totalPages}
            handlePageChange={handlePageChange}
          />
        </div>
      </div>

      <AlertModal 
        show={statusAlert}
        onHide={() => setStatusAlert(false)}
        title='Alert'
        message={`Change Personnel Location Status`}
        onConfirm={handleStatusChangeSubmit}
        />

      <AlertModal 
        show={addStatusAlert}
        onHide={() => setAddStatusAlert(false)}
        title='Alert'
        message={`${modalName}`}
        onConfirm={handleGradeSubmit}
        />

        <Modal
              className="top-right-modal"
              centered={false}
              show={showAddModal}
          >
            <div>
                <div className="modalHead">
                <p className="modalHeadText ms-3 pt-3 p-2">{modalName}</p>
                <div onClick={() => addGradeClose()} className="p-2 mx-3 my-2 ">
                  <TfiClose fill="#FFFFFF" />
                </div>
              </div>
              <div>
                <div className="selectWrap">
                  <div className="form-group my-1">
                    <label htmlFor="">{t('personnelMonitoring.personnelLocName')}<span style={{ color: 'red' }}>*</span></label>
                    <br />
                    <input 
                      id="personnelLocName"
                      value={addUserData.personnelLocName}
                      name="personnelLocName"
                      type='text'
                       placeholder={t('personnelMonitoring.personnelLocName')}
                       className="placeholder-style"
                      onChange={handleAddUserChange} 
                    />
                    {errors.personnelLocName && <p className="errorText">{errors.personnelLocName}</p>}
                  </div>

                  <div className="form-group my-1">
                    <label htmlFor="">{t('personnelMonitoring.locationCode')}<span style={{ color: 'red' }}>*</span></label>
                    <br />
                    <input 
                       id="locationCode"
                       value={addUserData.locationCode}
                       name="locationCode"
                       type='text'
                       placeholder={t('personnelMonitoring.locationCode')}
                       className="placeholder-style"
                       onChange={handleAddUserChange} 
                       disabled={addUserData.status === "inactive"}
                    />
                    {errors.locationCode && <p className="errorText">{errors.locationCode}</p>}
                  </div>


                  

                </div>
                <div className="selectWrap">
                <div className="form-group my-1">
                    <label htmlFor="">{t('personnelMonitoring.description')}<span style={{ color: 'red' }}>*</span></label>
                    <br />
                    <input 
                       id="description"
                       value={addUserData.description}
                       name="description"
                       type='text'
                       placeholder={t('personnelMonitoring.description')}
                       className="placeholder-style"
                       onChange={handleAddUserChange} 
                    />
                    {errors.description && <p className="errorText">{errors.description}</p>}
                </div>

                  {addUserData.id ? (
                  <div className="form-group my-1 ">
                      <label htmlFor="" className='ReasonLabel'>{t('userManagement.reason')} <span style={{ color: 'red' }}>*</span></label>
                      <br />
                      <input 
                          id="reason"
                          value={addUserData.reason}
                          name="reason"
                          type='text'
                          placeholder={t('userManagement.reason')}
                          className="reasonClass"
                          onChange={handleAddUserChange} 
                          onKeyDown={(e)=>checkSpecialChar(e)}
                      />
                      {errors.reason && <p className="errorText">{errors.reason}</p>}
                  </div>
                  ) : (
                    <div className="form-group my-1" style={{"visibility" : "hidden"}}>
                      <label htmlFor="">{t('userManagement.department')} <span style={{ color: 'red' }}>*</span></label>
                      <br />
                      <select
                        id="department"
                        // value={addUserData.department}
                        name="department"
                        // onChange={handleAddUserChange} 
                      >
                        <option value="">Select</option>
                      </select>
                  </div>
                  )}

                </div>
              </div>
              <div className="bottomArea">
            <button onClick={() => addGradeClose()} className="modalCancelBtn">
            {t('buttons.cancel')}
            </button>
            <button onClick={() => handleUserAddAlert()} className="squareOrangeBtn3">{t('buttons.submit')}</button>
          </div>
            </div>
        </Modal>
    </div>
  );
};

export default PersonnelMonitoringLocation;
